body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --wp-admin-theme-color: #007cba;
    --wp-admin-theme-color-darker-10: #006ba1;
    --wp-admin-theme-color-darker-20: #005a87;
}
#start-resizable-editor-section {
    display: none;
}
.wp-block-audio figcaption {
    color: #555d66;
    font-size: 13px;
    text-align: center;
}
.wp-block-code {
    font-family: Menlo, Consolas, monaco, monospace;
    font-size: 15px;
    color: #1e1e1e;
    padding: 0.8em 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.blocks-gallery-caption,
.wp-block-embed figcaption,
.wp-block-image figcaption {
    color: #555d66;
    font-size: 13px;
    text-align: center;
}
.wp-block-pullquote {
    border-top: 4px solid #555d66;
    border-bottom: 4px solid #555d66;
    margin-bottom: 28px;
    color: #40464d;
}
.wp-block-pullquote__citation,
.wp-block-pullquote cite,
.wp-block-pullquote footer {
    color: #40464d;
    text-transform: uppercase;
    font-size: 13px;
    font-style: normal;
}
.wp-block-navigation ul,
.wp-block-navigation ul li {
    list-style: none;
}
.wp-block-navigation-link.wp-block-navigation-link {
    margin: 0;
}
.wp-block-quote {
    border-left: 4px solid #000;
    margin: 0 0 28px;
    padding-left: 1em;
}
.wp-block-quote__citation,
.wp-block-quote cite,
.wp-block-quote footer {
    color: #6c7781;
    font-size: 13px;
    margin-top: 1em;
    position: relative;
    font-style: normal;
}
.wp-block-quote.has-text-align-right {
    border-left: none;
    border-right: 4px solid #000;
    padding-left: 0;
    padding-right: 1em;
}
.wp-block-quote.has-text-align-center {
    border: none;
    padding-left: 0;
}
.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
    border: none;
}
.wp-block-search .wp-block-search__label {
    font-weight: 700;
}
.wp-block-group.has-background {
    padding: 20px 30px;
    margin-top: 0;
    margin-bottom: 0;
}
.wp-block-separator {
    border: none;
    border-bottom: 2px solid #8f98a1;
    margin-left: auto;
    margin-right: auto;
}
.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
    max-width: 100px;
}
.wp-block-separator.has-background:not(.is-style-dots) {
    border-bottom: none;
    height: 1px;
}
.wp-block-separator.has-background:not(.is-style-wide):not(.is-style-dots) {
    height: 2px;
}
.wp-block-table {
    border-collapse: collapse;
}
.wp-block-table thead {
    border-bottom: 3px solid;
}
.wp-block-table tfoot {
    border-top: 3px solid;
}
.wp-block-table td,
.wp-block-table th {
    padding: 0.5em;
    border: 1px solid;
    word-break: normal;
}
.wp-block-table figcaption,
.wp-block-video figcaption {
    color: #555d66;
    font-size: 13px;
    text-align: center;
}
#end-resizable-editor-section {
    display: none;
}

:root {
    --wp-admin-theme-color: #007cba;
    --wp-admin-theme-color-darker-10: #006ba1;
    --wp-admin-theme-color-darker-20: #005a87;
}
#start-resizable-editor-section {
    display: none;
}
.wp-block-audio figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em;
}
.wp-block-audio audio {
    width: 100%;
    min-width: 300px;
}
.wp-block-button__link {
    color: #fff;
    background-color: #32373c;
    border: none;
    border-radius: 28px;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    overflow-wrap: break-word;
}
.wp-block-button__link:active,
.wp-block-button__link:focus,
.wp-block-button__link:hover,
.wp-block-button__link:visited {
    color: #fff;
}
.wp-block-button__link.aligncenter {
    text-align: center;
}
.wp-block-button__link.alignright {
    text-align: right;
}
.wp-block-button.is-style-squared,
.wp-block-button__link.wp-block-button.is-style-squared {
    border-radius: 0;
}
.wp-block-button.no-border-radius,
.wp-block-button__link.no-border-radius {
    border-radius: 0 !important;
}
.is-style-outline .wp-block-button__link,
.wp-block-button__link.is-style-outline {
    color: #32373c;
    background-color: transparent;
    border: 2px solid;
}
.wp-block-buttons .wp-block-button {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
}
.wp-block-buttons .wp-block-button:last-child {
    margin-right: 0;
}
.wp-block-buttons.alignright .wp-block-button {
    margin-right: 0;
    margin-left: 8px;
}
.wp-block-buttons.alignright .wp-block-button:first-child {
    margin-left: 0;
}
.wp-block-buttons.alignleft .wp-block-button {
    margin-left: 0;
    margin-right: 8px;
}
.wp-block-buttons.alignleft .wp-block-button:last-child {
    margin-right: 0;
}
.wp-block-button.aligncenter,
.wp-block-buttons.aligncenter,
.wp-block-calendar {
    text-align: center;
}
.wp-block-calendar tbody td,
.wp-block-calendar th {
    padding: 4px;
    border: 1px solid #ddd;
}
.wp-block-calendar tfoot td {
    border: none;
}
.wp-block-calendar table {
    width: 100%;
    border-collapse: collapse;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
}
.wp-block-calendar table th {
    font-weight: 400;
    background: #ddd;
}
.wp-block-calendar a {
    text-decoration: underline;
}
.wp-block-calendar tfoot a {
    color: #007cba;
    color: #007cba;
    color: var(--wp-admin-theme-color);
}
.wp-block-calendar table caption,
.wp-block-calendar table tbody {
    color: #40464d;
}
.wp-block-categories.alignleft {
    margin-right: 2em;
}
.wp-block-categories.alignright {
    margin-left: 2em;
}
.wp-block-columns {
    display: flex;
    margin-bottom: 28px;
    flex-wrap: wrap;
}
@media (min-width: 782px) {
    .wp-block-columns {
        flex-wrap: nowrap;
    }
}
.wp-block-columns.has-background {
    padding: 20px 38px;
}
.wp-block-column {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
}
@media (max-width: 599px) {
    .wp-block-column {
        flex-basis: 100% !important;
    }
}
@media (min-width: 600px) and (max-width: 781px) {
    .wp-block-column {
        flex-basis: calc(50% - 16px) !important;
        flex-grow: 0;
    }
    .wp-block-column:nth-child(2n) {
        margin-left: 32px;
    }
}
@media (min-width: 782px) {
    .wp-block-column {
        flex-basis: 0;
        flex-grow: 1;
    }
    .wp-block-column[style*="flex-basis"] {
        flex-grow: 0;
    }
    .wp-block-column:not(:first-child) {
        margin-left: 32px;
    }
}
.wp-block-columns.are-vertically-aligned-top {
    align-items: flex-start;
}
.wp-block-columns.are-vertically-aligned-center {
    align-items: center;
}
.wp-block-columns.are-vertically-aligned-bottom {
    align-items: flex-end;
}
.wp-block-column.is-vertically-aligned-top {
    align-self: flex-start;
}
.wp-block-column.is-vertically-aligned-center {
    -ms-grid-row-align: center;
    align-self: center;
}
.wp-block-column.is-vertically-aligned-bottom {
    align-self: flex-end;
}
.wp-block-column.is-vertically-aligned-bottom,
.wp-block-column.is-vertically-aligned-center,
.wp-block-column.is-vertically-aligned-top {
    width: 100%;
}
.wp-block-cover,
.wp-block-cover-image {
    position: relative;
    background-size: cover;
    background-position: 50%;
    min-height: 430px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
}
.wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax {
    background-attachment: fixed;
}
@supports (-webkit-overflow-scrolling: touch) {
    .wp-block-cover-image.has-parallax,
    .wp-block-cover.has-parallax {
        background-attachment: scroll;
    }
}
@media (prefers-reduced-motion: reduce) {
    .wp-block-cover-image.has-parallax,
    .wp-block-cover.has-parallax {
        background-attachment: scroll;
    }
}
.wp-block-cover-image.has-background-dim,
.wp-block-cover.has-background-dim {
    background-color: #000;
}
.wp-block-cover-image.has-background-dim:before,
.wp-block-cover.has-background-dim:before {
    content: "";
    background-color: inherit;
}
.wp-block-cover-image.has-background-dim:not(.has-background-gradient):before,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim:not(.has-background-gradient):before,
.wp-block-cover .wp-block-cover__gradient-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}
.wp-block-cover-image.has-background-dim:not(.has-background-gradient):before,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim:not(.has-background-gradient):before,
.wp-block-cover .wp-block-cover__gradient-background {
    opacity: 0.5;
}
.wp-block-cover-image.has-background-dim.has-background-dim-10:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-10:not(.has-background-gradient):before {
    opacity: 0.1;
}
.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background {
    opacity: 0.1;
}
.wp-block-cover-image.has-background-dim.has-background-dim-20:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-20:not(.has-background-gradient):before {
    opacity: 0.2;
}
.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background {
    opacity: 0.2;
}
.wp-block-cover-image.has-background-dim.has-background-dim-30:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-30:not(.has-background-gradient):before {
    opacity: 0.3;
}
.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background {
    opacity: 0.3;
}
.wp-block-cover-image.has-background-dim.has-background-dim-40:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-40:not(.has-background-gradient):before {
    opacity: 0.4;
}
.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background {
    opacity: 0.4;
}
.wp-block-cover-image.has-background-dim.has-background-dim-50:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-50:not(.has-background-gradient):before {
    opacity: 0.5;
}
.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background {
    opacity: 0.5;
}
.wp-block-cover-image.has-background-dim.has-background-dim-60:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-60:not(.has-background-gradient):before {
    opacity: 0.6;
}
.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background {
    opacity: 0.6;
}
.wp-block-cover-image.has-background-dim.has-background-dim-70:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-70:not(.has-background-gradient):before {
    opacity: 0.7;
}
.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background {
    opacity: 0.7;
}
.wp-block-cover-image.has-background-dim.has-background-dim-80:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-80:not(.has-background-gradient):before {
    opacity: 0.8;
}
.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background {
    opacity: 0.8;
}
.wp-block-cover-image.has-background-dim.has-background-dim-90:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-90:not(.has-background-gradient):before {
    opacity: 0.9;
}
.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background {
    opacity: 0.9;
}
.wp-block-cover-image.has-background-dim.has-background-dim-100:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-100:not(.has-background-gradient):before {
    opacity: 1;
}
.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background {
    opacity: 1;
}
.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
    max-width: 290px;
    width: 100%;
}
.wp-block-cover-image:after,
.wp-block-cover:after {
    display: block;
    content: "";
    font-size: 0;
    min-height: inherit;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .wp-block-cover-image:after,
    .wp-block-cover:after {
        content: none;
    }
}
.wp-block-cover-image.aligncenter,
.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.aligncenter,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
    display: flex;
}
.wp-block-cover-image .wp-block-cover__inner-container,
.wp-block-cover .wp-block-cover__inner-container {
    width: calc(100% - 70px);
    z-index: 1;
    color: #fff;
}
.wp-block-cover-image .wp-block-subhead:not(.has-text-color),
.wp-block-cover-image h1:not(.has-text-color),
.wp-block-cover-image h2:not(.has-text-color),
.wp-block-cover-image h3:not(.has-text-color),
.wp-block-cover-image h4:not(.has-text-color),
.wp-block-cover-image h5:not(.has-text-color),
.wp-block-cover-image h6:not(.has-text-color),
.wp-block-cover-image p:not(.has-text-color),
.wp-block-cover .wp-block-subhead:not(.has-text-color),
.wp-block-cover h1:not(.has-text-color),
.wp-block-cover h2:not(.has-text-color),
.wp-block-cover h3:not(.has-text-color),
.wp-block-cover h4:not(.has-text-color),
.wp-block-cover h5:not(.has-text-color),
.wp-block-cover h6:not(.has-text-color),
.wp-block-cover p:not(.has-text-color) {
    color: inherit;
}
.wp-block-cover-image.is-position-top-left,
.wp-block-cover.is-position-top-left {
    align-items: flex-start;
    justify-content: flex-start;
}
.wp-block-cover-image.is-position-top-center,
.wp-block-cover.is-position-top-center {
    align-items: flex-start;
    justify-content: center;
}
.wp-block-cover-image.is-position-top-right,
.wp-block-cover.is-position-top-right {
    align-items: flex-start;
    justify-content: flex-end;
}
.wp-block-cover-image.is-position-center-left,
.wp-block-cover.is-position-center-left {
    align-items: center;
    justify-content: flex-start;
}
.wp-block-cover-image.is-position-center-center,
.wp-block-cover.is-position-center-center {
    align-items: center;
    justify-content: center;
}
.wp-block-cover-image.is-position-center-right,
.wp-block-cover.is-position-center-right {
    align-items: center;
    justify-content: flex-end;
}
.wp-block-cover-image.is-position-bottom-left,
.wp-block-cover.is-position-bottom-left {
    align-items: flex-end;
    justify-content: flex-start;
}
.wp-block-cover-image.is-position-bottom-center,
.wp-block-cover.is-position-bottom-center {
    align-items: flex-end;
    justify-content: center;
}
.wp-block-cover-image.is-position-bottom-right,
.wp-block-cover.is-position-bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
}
.wp-block-cover-image.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container {
    margin: 0;
    width: auto;
}
.wp-block-cover__video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
}
.wp-block-cover-image-text,
.wp-block-cover-text,
section.wp-block-cover-image h2 {
    color: #fff;
}
.wp-block-cover-image-text a,
.wp-block-cover-image-text a:active,
.wp-block-cover-image-text a:focus,
.wp-block-cover-image-text a:hover,
.wp-block-cover-text a,
.wp-block-cover-text a:active,
.wp-block-cover-text a:focus,
.wp-block-cover-text a:hover,
section.wp-block-cover-image h2 a,
section.wp-block-cover-image h2 a:active,
section.wp-block-cover-image h2 a:focus,
section.wp-block-cover-image h2 a:hover {
    color: #fff;
}
.wp-block-cover-image .wp-block-cover.has-left-content {
    justify-content: flex-start;
}
.wp-block-cover-image .wp-block-cover.has-right-content {
    justify-content: flex-end;
}
.wp-block-cover-image.has-left-content .wp-block-cover-image-text,
.wp-block-cover.has-left-content .wp-block-cover-text,
section.wp-block-cover-image.has-left-content > h2 {
    margin-left: 0;
    text-align: left;
}
.wp-block-cover-image.has-right-content .wp-block-cover-image-text,
.wp-block-cover.has-right-content .wp-block-cover-text,
section.wp-block-cover-image.has-right-content > h2 {
    margin-right: 0;
    text-align: right;
}
.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text,
section.wp-block-cover-image > h2 {
    font-size: 2em;
    line-height: 1.25;
    z-index: 1;
    margin-bottom: 0;
    max-width: 580px;
    padding: 14px;
    text-align: center;
}
.wp-block-embed.alignleft,
.wp-block-embed.alignright,
.wp-block[data-align="left"] > [data-type^="core-embed"],
.wp-block[data-align="right"] > [data-type^="core-embed"] {
    max-width: 360px;
    width: 100%;
}
.wp-block-embed.alignleft .wp-block-embed__wrapper,
.wp-block-embed.alignright .wp-block-embed__wrapper,
.wp-block[data-align="left"] > [data-type^="core-embed"] .wp-block-embed__wrapper,
.wp-block[data-align="right"] > [data-type^="core-embed"] .wp-block-embed__wrapper {
    min-width: 280px;
}
.wp-block-embed {
    margin-bottom: 1em;
}
.wp-block-embed figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em;
}
.wp-block-embed iframe {
    max-width: 100%;
}
.wp-block-embed__wrapper {
    position: relative;
}
.wp-embed-responsive .wp-has-aspect-ratio .wp-block-embed__wrapper:before {
    content: "";
    display: block;
    padding-top: 50%;
}
.wp-embed-responsive .wp-has-aspect-ratio iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.wp-embed-responsive .wp-embed-aspect-21-9 .wp-block-embed__wrapper:before {
    padding-top: 42.85%;
}
.wp-embed-responsive .wp-embed-aspect-18-9 .wp-block-embed__wrapper:before {
    padding-top: 50%;
}
.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper:before {
    padding-top: 56.25%;
}
.wp-embed-responsive .wp-embed-aspect-4-3 .wp-block-embed__wrapper:before {
    padding-top: 75%;
}
.wp-embed-responsive .wp-embed-aspect-1-1 .wp-block-embed__wrapper:before {
    padding-top: 100%;
}
.wp-embed-responsive .wp-embed-aspect-9-6 .wp-block-embed__wrapper:before {
    padding-top: 66.66%;
}
.wp-embed-responsive .wp-embed-aspect-1-2 .wp-block-embed__wrapper:before {
    padding-top: 200%;
}
.wp-block-file {
    margin-bottom: 1.5em;
}
.wp-block-file.aligncenter {
    text-align: center;
}
.wp-block-file.alignright {
    text-align: right;
}
.wp-block-file .wp-block-file__button {
    background: #32373c;
    border-radius: 2em;
    color: #fff;
    font-size: 13px;
    padding: 0.5em 1em;
}
.wp-block-file a.wp-block-file__button {
    text-decoration: none;
}
.wp-block-file a.wp-block-file__button:active,
.wp-block-file a.wp-block-file__button:focus,
.wp-block-file a.wp-block-file__button:hover,
.wp-block-file a.wp-block-file__button:visited {
    box-shadow: none;
    color: #fff;
    opacity: 0.85;
    text-decoration: none;
}
.wp-block-file * + .wp-block-file__button {
    margin-left: 0.75em;
}
.blocks-gallery-grid,
.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    margin: 0 16px 16px 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.blocks-gallery-grid .blocks-gallery-image figure,
.blocks-gallery-grid .blocks-gallery-item figure,
.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
    margin: 0;
    height: 100%;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .blocks-gallery-grid .blocks-gallery-image figure,
    .blocks-gallery-grid .blocks-gallery-item figure,
    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
    }
}
.blocks-gallery-grid .blocks-gallery-image img,
.blocks-gallery-grid .blocks-gallery-item img,
.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .blocks-gallery-grid .blocks-gallery-image img,
    .blocks-gallery-grid .blocks-gallery-item img,
    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
        width: auto;
    }
}
.blocks-gallery-grid .blocks-gallery-image figcaption,
.blocks-gallery-grid .blocks-gallery-item figcaption,
.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 40px 10px 9px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 70%, transparent);
}
.blocks-gallery-grid .blocks-gallery-image figcaption img,
.blocks-gallery-grid .blocks-gallery-item figcaption img,
.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline;
}
.blocks-gallery-grid.is-cropped .blocks-gallery-image a,
.blocks-gallery-grid.is-cropped .blocks-gallery-image img,
.blocks-gallery-grid.is-cropped .blocks-gallery-item a,
.blocks-gallery-grid.is-cropped .blocks-gallery-item img,
.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .blocks-gallery-grid.is-cropped .blocks-gallery-image a,
    .blocks-gallery-grid.is-cropped .blocks-gallery-image img,
    .blocks-gallery-grid.is-cropped .blocks-gallery-item a,
    .blocks-gallery-grid.is-cropped .blocks-gallery-item img,
    .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .wp-block-gallery.is-cropped .blocks-gallery-item img {
        height: 100%;
        flex: 1 1;
        object-fit: cover;
    }
}
.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 16px);
}
.blocks-gallery-grid .blocks-gallery-image:nth-of-type(2n),
.blocks-gallery-grid .blocks-gallery-item:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0;
}
.blocks-gallery-grid.columns-1 .blocks-gallery-image,
.blocks-gallery-grid.columns-1 .blocks-gallery-item,
.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
    width: 100%;
    margin-right: 0;
}
@media (min-width: 600px) {
    .blocks-gallery-grid.columns-3 .blocks-gallery-image,
    .blocks-gallery-grid.columns-3 .blocks-gallery-item,
    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
        width: calc(33.33333% - 10.66667px);
        margin-right: 16px;
    }
    .blocks-gallery-grid.columns-4 .blocks-gallery-image,
    .blocks-gallery-grid.columns-4 .blocks-gallery-item,
    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
        width: calc(25% - 12px);
        margin-right: 16px;
    }
    .blocks-gallery-grid.columns-5 .blocks-gallery-image,
    .blocks-gallery-grid.columns-5 .blocks-gallery-item,
    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
        width: calc(20% - 12.8px);
        margin-right: 16px;
    }
    .blocks-gallery-grid.columns-6 .blocks-gallery-image,
    .blocks-gallery-grid.columns-6 .blocks-gallery-item,
    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
        width: calc(16.66667% - 13.33333px);
        margin-right: 16px;
    }
    .blocks-gallery-grid.columns-7 .blocks-gallery-image,
    .blocks-gallery-grid.columns-7 .blocks-gallery-item,
    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
        width: calc(14.28571% - 13.71429px);
        margin-right: 16px;
    }
    .blocks-gallery-grid.columns-8 .blocks-gallery-image,
    .blocks-gallery-grid.columns-8 .blocks-gallery-item,
    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
        width: calc(12.5% - 14px);
        margin-right: 16px;
    }
    .blocks-gallery-grid.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .blocks-gallery-grid.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n) {
        margin-right: 0;
    }
    .blocks-gallery-grid.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .blocks-gallery-grid.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n) {
        margin-right: 0;
    }
    .blocks-gallery-grid.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .blocks-gallery-grid.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n) {
        margin-right: 0;
    }
    .blocks-gallery-grid.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .blocks-gallery-grid.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n) {
        margin-right: 0;
    }
    .blocks-gallery-grid.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .blocks-gallery-grid.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n) {
        margin-right: 0;
    }
    .blocks-gallery-grid.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .blocks-gallery-grid.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n) {
        margin-right: 0;
    }
    .blocks-gallery-grid.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .blocks-gallery-grid.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n) {
        margin-right: 0;
    }
    .blocks-gallery-grid.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .blocks-gallery-grid.columns-8 .blocks-gallery-item:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0;
    }
}
.blocks-gallery-grid .blocks-gallery-image:last-child,
.blocks-gallery-grid .blocks-gallery-item:last-child,
.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0;
}
.blocks-gallery-grid.alignleft,
.blocks-gallery-grid.alignright,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    max-width: 290px;
    width: 100%;
}
.blocks-gallery-grid.aligncenter .blocks-gallery-item figure,
.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center;
}
h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
    padding: 20px 38px;
}
.wp-block-image {
    margin-bottom: 1em;
}
.wp-block-image img {
    max-width: 100%;
}
.wp-block-image.aligncenter {
    text-align: center;
}
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
    width: 100%;
}
.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.is-resized {
    display: table;
}
.wp-block-image .aligncenter > figcaption,
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image.is-resized > figcaption {
    display: table-caption;
    caption-side: bottom;
}
.wp-block-image .alignleft {
    float: left;
    margin: 0.5em 1em 0.5em 0;
}
.wp-block-image .alignright {
    float: right;
    margin: 0.5em 0 0.5em 1em;
}
.wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto;
}
.wp-block-image figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em;
}
.is-style-circle-mask img,
.is-style-rounded img {
    border-radius: 9999px;
}
@supports ((-webkit-mask-image: none) or (mask-image: none)) or (-webkit-mask-image: none) {
    .is-style-circle-mask img {
        -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
        mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
        mask-mode: alpha;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;
        border-radius: 0;
    }
}
.wp-block-latest-comments__comment {
    font-size: 15px;
    line-height: 1.1;
    list-style: none;
    margin-bottom: 1em;
}
.has-avatars .wp-block-latest-comments__comment {
    min-height: 36px;
    list-style: none;
}
.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt,
.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    margin-left: 52px;
}
.has-dates .wp-block-latest-comments__comment,
.has-excerpts .wp-block-latest-comments__comment {
    line-height: 1.5;
}
.wp-block-latest-comments__comment-excerpt p {
    font-size: 14px;
    line-height: 1.8;
    margin: 5px 0 20px;
}
.wp-block-latest-comments__comment-date {
    color: #8f98a1;
    display: block;
    font-size: 12px;
}
.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
    border-radius: 24px;
    display: block;
    float: left;
    height: 40px;
    margin-right: 12px;
    width: 40px;
}
.wp-block-latest-posts.alignleft {
    margin-right: 2em;
}
.wp-block-latest-posts.alignright {
    margin-left: 2em;
}
.wp-block-latest-posts.wp-block-latest-posts__list {
    list-style: none;
}
.wp-block-latest-posts.wp-block-latest-posts__list li {
    clear: both;
}
.wp-block-latest-posts.is-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.wp-block-latest-posts.is-grid li {
    margin: 0 20px 20px 0;
    width: 100%;
}
@media (min-width: 600px) {
    .wp-block-latest-posts.columns-2 li {
        width: calc(50% - 20px);
    }
    .wp-block-latest-posts.columns-3 li {
        width: calc(33.33333% - 20px);
    }
    .wp-block-latest-posts.columns-4 li {
        width: calc(25% - 20px);
    }
    .wp-block-latest-posts.columns-5 li {
        width: calc(20% - 20px);
    }
    .wp-block-latest-posts.columns-6 li {
        width: calc(16.66667% - 20px);
    }
}
.wp-block-latest-posts__post-author,
.wp-block-latest-posts__post-date {
    display: block;
    color: #6c7781;
    font-size: 13px;
}
.wp-block-latest-posts__post-excerpt {
    margin-top: 8px;
    margin-bottom: 16px;
}
.wp-block-latest-posts__featured-image img {
    height: auto;
    width: auto;
}
.wp-block-latest-posts__featured-image.alignleft {
    margin-right: 1em;
}
.wp-block-latest-posts__featured-image.alignright {
    margin-left: 1em;
}
.wp-block-latest-posts__featured-image.aligncenter {
    margin-bottom: 1em;
    text-align: center;
}
.edit-post-visual-editor .wp-block-latest-posts.is-grid li {
    margin-bottom: 20px;
}
.wp-block-media-text {
    /*!rtl:begin:ignore*/
    direction: ltr;
    /*!rtl:end:ignore*/
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 1fr;
    grid-template-columns: 50% 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.wp-block-media-text.has-media-on-the-right {
    -ms-grid-columns: 1fr 50%;
    grid-template-columns: 1fr 50%;
}
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
    -ms-grid-row-align: start;
    align-self: start;
}
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media,
.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media {
    -ms-grid-row-align: center;
    align-self: center;
}
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
    -ms-grid-row-align: end;
    align-self: end;
}
.wp-block-media-text .wp-block-media-text__media {
    /*!rtl:begin:ignore*/
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
    /*!rtl:end:ignore*/
    margin: 0;
}
.wp-block-media-text .wp-block-media-text__content {
    direction: ltr;
    /*!rtl:begin:ignore*/
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
    /*!rtl:end:ignore*/
    padding: 0 8%;
    word-break: break-word;
}
.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
    /*!rtl:begin:ignore*/
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
    /*!rtl:end:ignore*/
}
.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
    /*!rtl:begin:ignore*/
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
    /*!rtl:end:ignore*/
}
.wp-block-media-text__media img,
.wp-block-media-text__media video {
    max-width: unset;
    width: 100%;
    vertical-align: middle;
}
.wp-block-media-text.is-image-fill figure.wp-block-media-text__media {
    height: 100%;
    min-height: 250px;
    background-size: cover;
}
.wp-block-media-text.is-image-fill figure.wp-block-media-text__media > img {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
@media (max-width: 600px) {
    .wp-block-media-text.is-stacked-on-mobile {
        -ms-grid-columns: 100% !important;
        grid-template-columns: 100% !important;
    }
    .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 1;
        grid-row: 1;
    }
    .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right .wp-block-media-text__media,
    .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 2;
        grid-row: 2;
    }
    .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right .wp-block-media-text__content {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 1;
        grid-row: 1;
    }
}
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-text-color),
.wp-block-navigation .wp-block-navigation-link:not(.has-text-color) {
    color: #1e1e1e;
}
.wp-block-navigation.is-style-light:not(.has-background) .wp-block-navigation__container,
.wp-block-navigation:not(.has-background) .wp-block-navigation__container {
    background-color: #fff;
}
.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-text-color) {
    color: #fff;
}
.wp-block-navigation.is-style-dark:not(.has-background) .wp-block-navigation__container {
    background-color: #1e1e1e;
}
.items-justified-left > ul {
    justify-content: flex-start;
}
.items-justified-center > ul {
    justify-content: center;
}
.items-justified-right > ul {
    justify-content: flex-end;
}
.wp-block-navigation-link {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0;
}
.wp-block-navigation-link .wp-block-navigation__container:empty {
    display: none;
}
.wp-block-navigation__container {
    list-style: none;
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
}
.is-vertical .wp-block-navigation__container {
    display: block;
}
.has-child .wp-block-navigation__container {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: inherit;
    color: inherit;
    position: absolute;
    left: 0;
    top: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.1s linear;
    visibility: hidden;
}
.has-child .wp-block-navigation__container > .wp-block-navigation-link > .wp-block-navigation-link__content {
    flex-grow: 1;
}
.has-child .wp-block-navigation__container > .wp-block-navigation-link > .wp-block-navigation-link__submenu-icon {
    padding-right: 8px;
}
@media (min-width: 782px) {
    .has-child .wp-block-navigation__container {
        left: 24px;
    }
    .has-child .wp-block-navigation__container .wp-block-navigation__container {
        left: 100%;
        top: -1px;
    }
    .has-child .wp-block-navigation__container .wp-block-navigation__container:before {
        content: "";
        position: absolute;
        right: 100%;
        height: 100%;
        display: block;
        width: 8px;
        background: transparent;
    }
    .has-child .wp-block-navigation__container .wp-block-navigation-link__submenu-icon svg {
        transform: rotate(0);
    }
}
.has-child:hover {
    cursor: pointer;
}
.has-child:hover > .wp-block-navigation__container {
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
}
.has-child:focus-within {
    cursor: pointer;
}
.has-child:focus-within > .wp-block-navigation__container {
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: column;
}
.wp-block-navigation-link__content {
    text-decoration: none;
    padding: 8px 16px;
}
.wp-block-navigation-link__content + .wp-block-navigation-link__content {
    padding-top: 0;
}
.has-text-color .wp-block-navigation-link__content {
    color: inherit;
}
.wp-block-navigation-link__label {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    word-break: normal;
    overflow-wrap: break-word;
}
.wp-block-navigation-link__submenu-icon {
    height: inherit;
    padding: 6px 16px;
}
.wp-block-navigation-link__submenu-icon svg {
    fill: currentColor;
}
@media (min-width: 782px) {
    .wp-block-navigation-link__submenu-icon svg {
        transform: rotate(90deg);
    }
}
.is-small-text {
    font-size: 14px;
}
.is-regular-text {
    font-size: 16px;
}
.is-large-text {
    font-size: 36px;
}
.is-larger-text {
    font-size: 48px;
}
.has-drop-cap:not(:focus):first-letter {
    float: left;
    font-size: 8.4em;
    line-height: 0.68;
    font-weight: 100;
    margin: 0.05em 0.1em 0 0;
    text-transform: uppercase;
    font-style: normal;
}
p.has-background {
    padding: 20px 38px;
}
p.has-text-color a {
    color: inherit;
}
.wp-block-post-author {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.5;
}
.wp-block-post-author__byline {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.5em;
}
.wp-block-post-author__avatar {
    margin-right: 16px;
}
.wp-block-post-author__bio {
    margin-bottom: 8px;
    font-size: 0.7em;
}
.wp-block-post-author__content {
    flex-grow: 1;
    flex-basis: 0;
}
.wp-block-post-author__name {
    font-weight: 700;
    margin: 0;
}
.wp-block-pullquote {
    padding: 3em 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
}
.wp-block-pullquote.alignleft,
.wp-block-pullquote.alignright {
    max-width: 290px;
}
.wp-block-pullquote.alignleft p,
.wp-block-pullquote.alignright p {
    font-size: 20px;
}
.wp-block-pullquote p {
    font-size: 28px;
    line-height: 1.6;
}
.wp-block-pullquote cite,
.wp-block-pullquote footer {
    position: relative;
}
.wp-block-pullquote .has-text-color a {
    color: inherit;
}
.wp-block-pullquote:not(.is-style-solid-color) {
    background: none;
}
.wp-block-pullquote.is-style-solid-color {
    border: none;
}
.wp-block-pullquote.is-style-solid-color blockquote {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    max-width: 60%;
}
.wp-block-pullquote.is-style-solid-color blockquote p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
}
.wp-block-pullquote.is-style-solid-color blockquote cite {
    text-transform: none;
    font-style: normal;
}
.wp-block-pullquote cite {
    color: inherit;
}
.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
    margin: 0 0 16px;
    padding: 0 1em;
}
.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
    font-size: 24px;
    font-style: italic;
    line-height: 1.6;
}
.wp-block-quote.is-large cite,
.wp-block-quote.is-large footer,
.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer {
    font-size: 18px;
    text-align: right;
}
.wp-block-rss.alignleft {
    margin-right: 2em;
}
.wp-block-rss.alignright {
    margin-left: 2em;
}
.wp-block-rss.is-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}
.wp-block-rss.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%;
}
@media (min-width: 600px) {
    .wp-block-rss.columns-2 li {
        width: calc(50% - 16px);
    }
    .wp-block-rss.columns-3 li {
        width: calc(33.33333% - 16px);
    }
    .wp-block-rss.columns-4 li {
        width: calc(25% - 16px);
    }
    .wp-block-rss.columns-5 li {
        width: calc(20% - 16px);
    }
    .wp-block-rss.columns-6 li {
        width: calc(16.66667% - 16px);
    }
}
.wp-block-rss__item-author,
.wp-block-rss__item-publish-date {
    display: block;
    color: #6c7781;
    font-size: 13px;
}
.wp-block-search {
    display: flex;
    flex-wrap: wrap;
}
.wp-block-search .wp-block-search__label {
    width: 100%;
}
.wp-block-search .wp-block-search__input {
    flex-grow: 1;
    max-width: 360px;
}
.wp-block-search .wp-block-search__button {
    margin-left: 10px;
}
.wp-block-separator.is-style-wide {
    border-bottom-width: 1px;
}
.wp-block-separator.is-style-dots {
    background: none !important;
    border: none;
    text-align: center;
    max-width: none;
    line-height: 1;
    height: auto;
}
.wp-block-separator.is-style-dots:before {
    content: "\B7   \B7   \B7";
    color: currentColor;
    font-size: 20px;
    letter-spacing: 2em;
    padding-left: 2em;
    font-family: serif;
}
.wp-block-social-links {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
}
.wp-block-social-links .wp-social-link a,
.wp-block-social-links .wp-social-link a:hover {
    text-decoration: none;
    border-bottom: 0;
    box-shadow: none;
}
.wp-social-link {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    margin-right: 8px;
    transition: transform 0.1s ease;
}
@media (prefers-reduced-motion: reduce) {
    .wp-social-link {
        transition-duration: 0s;
    }
}
.wp-social-link a {
    padding: 6px;
    display: block;
    line-height: 0;
    transition: transform 0.1s ease;
}
.wp-social-link a,
.wp-social-link a:active,
.wp-social-link a:hover,
.wp-social-link a:visited,
.wp-social-link svg {
    color: currentColor;
    fill: currentColor;
}
.wp-social-link:hover {
    transform: scale(1.1);
}
.wp-block-social-links.aligncenter {
    justify-content: center;
    display: flex;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link {
    background-color: #f0f0f0;
    color: #444;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-amazon {
    background-color: #f90;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-bandcamp {
    background-color: #1ea0c3;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-behance {
    background-color: #0757fe;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-codepen {
    background-color: #1e1f26;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-deviantart {
    background-color: #02e49b;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dribbble {
    background-color: #e94c89;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dropbox {
    background-color: #4280ff;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-etsy {
    background-color: #f45800;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-facebook {
    background-color: #1778f2;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-fivehundredpx {
    background-color: #000;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-flickr {
    background-color: #0461dd;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-foursquare {
    background-color: #e65678;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-github {
    background-color: #24292d;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-goodreads {
    background-color: #eceadd;
    color: #382110;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-google {
    background-color: #ea4434;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-instagram {
    background-color: #f00075;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-lastfm {
    background-color: #e21b24;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-linkedin {
    background-color: #0d66c2;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-mastodon {
    background-color: #3288d4;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-medium {
    background-color: #02ab6c;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-meetup {
    background-color: #f6405f;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pinterest {
    background-color: #e60122;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pocket {
    background-color: #ef4155;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-reddit {
    background-color: #fe4500;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-skype {
    background-color: #0478d7;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-snapchat {
    background-color: #fefc00;
    color: #fff;
    stroke: #000;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-soundcloud {
    background-color: #ff5600;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-spotify {
    background-color: #1bd760;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tumblr {
    background-color: #011835;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitch {
    background-color: #6440a4;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitter {
    background-color: #1da1f2;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vimeo {
    background-color: #1eb7ea;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vk {
    background-color: #4680c2;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-wordpress {
    background-color: #3499cd;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-yelp {
    background-color: #d32422;
    color: #fff;
}
.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-youtube {
    background-color: red;
    color: #fff;
}
.wp-block-social-links.is-style-logos-only .wp-social-link {
    background: none;
    padding: 4px;
}
.wp-block-social-links.is-style-logos-only .wp-social-link svg {
    width: 28px;
    height: 28px;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-amazon {
    color: #f90;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-bandcamp {
    color: #1ea0c3;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-behance {
    color: #0757fe;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-codepen {
    color: #1e1f26;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-deviantart {
    color: #02e49b;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-dribbble {
    color: #e94c89;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-dropbox {
    color: #4280ff;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-etsy {
    color: #f45800;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-facebook {
    color: #1778f2;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-fivehundredpx {
    color: #000;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-flickr {
    color: #0461dd;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-foursquare {
    color: #e65678;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-github {
    color: #24292d;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-goodreads {
    color: #382110;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-google {
    color: #ea4434;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-instagram {
    color: #f00075;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-lastfm {
    color: #e21b24;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-linkedin {
    color: #0d66c2;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-mastodon {
    color: #3288d4;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-medium {
    color: #02ab6c;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-meetup {
    color: #f6405f;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-pinterest {
    color: #e60122;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-pocket {
    color: #ef4155;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-reddit {
    color: #fe4500;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-skype {
    color: #0478d7;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-snapchat {
    color: #fff;
    stroke: #000;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-soundcloud {
    color: #ff5600;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-spotify {
    color: #1bd760;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-tumblr {
    color: #011835;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-twitch {
    color: #6440a4;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-twitter {
    color: #1da1f2;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-vimeo {
    color: #1eb7ea;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-vk {
    color: #4680c2;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-wordpress {
    color: #3499cd;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-yelp {
    background-color: #d32422;
    color: #fff;
}
.wp-block-social-links.is-style-logos-only .wp-social-link-youtube {
    color: red;
}
.wp-block-social-links.is-style-pill-shape .wp-social-link {
    width: auto;
}
.wp-block-social-links.is-style-pill-shape .wp-social-link a {
    padding-left: 16px;
    padding-right: 16px;
}
.wp-block-spacer {
    clear: both;
}
p.wp-block-subhead {
    font-size: 1.1em;
    font-style: italic;
    opacity: 0.75;
}
.wp-block-table {
    overflow-x: auto;
}
.wp-block-table table {
    width: 100%;
}
.wp-block-table .has-fixed-layout {
    table-layout: fixed;
    width: 100%;
}
.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
    word-break: break-word;
}
.wp-block-table.aligncenter,
.wp-block-table.alignleft,
.wp-block-table.alignright {
    display: table;
    width: auto;
}
.wp-block-table.aligncenter td,
.wp-block-table.aligncenter th,
.wp-block-table.alignleft td,
.wp-block-table.alignleft th,
.wp-block-table.alignright td,
.wp-block-table.alignright th {
    word-break: break-word;
}
.wp-block-table .has-subtle-light-gray-background-color {
    background-color: #f3f4f5;
}
.wp-block-table .has-subtle-pale-green-background-color {
    background-color: #e9fbe5;
}
.wp-block-table .has-subtle-pale-blue-background-color {
    background-color: #e7f5fe;
}
.wp-block-table .has-subtle-pale-pink-background-color {
    background-color: #fcf0ef;
}
.wp-block-table.is-style-stripes {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    border-bottom: 1px solid #f0f0f0;
}
.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: #f0f0f0;
}
.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
    background-color: #f3f4f5;
}
.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
    background-color: #e9fbe5;
}
.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
    background-color: #e7f5fe;
}
.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
    background-color: #fcf0ef;
}
.wp-block-table.is-style-stripes td,
.wp-block-table.is-style-stripes th {
    border-color: transparent;
}
.wp-block-text-columns,
.wp-block-text-columns.aligncenter {
    display: flex;
}
.wp-block-text-columns .wp-block-column {
    margin: 0 16px;
    padding: 0;
}
.wp-block-text-columns .wp-block-column:first-child {
    margin-left: 0;
}
.wp-block-text-columns .wp-block-column:last-child {
    margin-right: 0;
}
.wp-block-text-columns.columns-2 .wp-block-column {
    width: 50%;
}
.wp-block-text-columns.columns-3 .wp-block-column {
    width: 33.33333%;
}
.wp-block-text-columns.columns-4 .wp-block-column {
    width: 25%;
}
.wp-block-video {
    margin-left: 0;
    margin-right: 0;
}
.wp-block-video video {
    max-width: 100%;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
    .wp-block-video [poster] {
        object-fit: cover;
    }
}
.wp-block-video.aligncenter {
    text-align: center;
}
.wp-block-video figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em;
}
:root .editor-styles-wrapper .has-pale-pink-background-color,
:root .has-pale-pink-background-color {
    background-color: #f78da7;
}
:root .editor-styles-wrapper .has-vivid-red-background-color,
:root .has-vivid-red-background-color {
    background-color: #cf2e2e;
}
:root .editor-styles-wrapper .has-luminous-vivid-orange-background-color,
:root .has-luminous-vivid-orange-background-color {
    background-color: #ff6900;
}
:root .editor-styles-wrapper .has-luminous-vivid-amber-background-color,
:root .has-luminous-vivid-amber-background-color {
    background-color: #fcb900;
}
:root .editor-styles-wrapper .has-light-green-cyan-background-color,
:root .has-light-green-cyan-background-color {
    background-color: #7bdcb5;
}
:root .editor-styles-wrapper .has-vivid-green-cyan-background-color,
:root .has-vivid-green-cyan-background-color {
    background-color: #00d084;
}
:root .editor-styles-wrapper .has-pale-cyan-blue-background-color,
:root .has-pale-cyan-blue-background-color {
    background-color: #8ed1fc;
}
:root .editor-styles-wrapper .has-vivid-cyan-blue-background-color,
:root .has-vivid-cyan-blue-background-color {
    background-color: #0693e3;
}
:root .editor-styles-wrapper .has-vivid-purple-background-color,
:root .has-vivid-purple-background-color {
    background-color: #9b51e0;
}
:root .editor-styles-wrapper .has-white-background-color,
:root .has-white-background-color {
    background-color: #fff;
}
:root .editor-styles-wrapper .has-very-light-gray-background-color,
:root .has-very-light-gray-background-color {
    background-color: #eee;
}
:root .editor-styles-wrapper .has-cyan-bluish-gray-background-color,
:root .has-cyan-bluish-gray-background-color {
    background-color: #abb8c3;
}
:root .editor-styles-wrapper .has-very-dark-gray-background-color,
:root .has-very-dark-gray-background-color {
    background-color: #313131;
}
:root .editor-styles-wrapper .has-black-background-color,
:root .has-black-background-color {
    background-color: #000;
}
:root .editor-styles-wrapper .has-pale-pink-color,
:root .has-pale-pink-color {
    color: #f78da7;
}
:root .editor-styles-wrapper .has-vivid-red-color,
:root .has-vivid-red-color {
    color: #cf2e2e;
}
:root .editor-styles-wrapper .has-luminous-vivid-orange-color,
:root .has-luminous-vivid-orange-color {
    color: #ff6900;
}
:root .editor-styles-wrapper .has-luminous-vivid-amber-color,
:root .has-luminous-vivid-amber-color {
    color: #fcb900;
}
:root .editor-styles-wrapper .has-light-green-cyan-color,
:root .has-light-green-cyan-color {
    color: #7bdcb5;
}
:root .editor-styles-wrapper .has-vivid-green-cyan-color,
:root .has-vivid-green-cyan-color {
    color: #00d084;
}
:root .editor-styles-wrapper .has-pale-cyan-blue-color,
:root .has-pale-cyan-blue-color {
    color: #8ed1fc;
}
:root .editor-styles-wrapper .has-vivid-cyan-blue-color,
:root .has-vivid-cyan-blue-color {
    color: #0693e3;
}
:root .editor-styles-wrapper .has-vivid-purple-color,
:root .has-vivid-purple-color {
    color: #9b51e0;
}
:root .editor-styles-wrapper .has-white-color,
:root .has-white-color {
    color: #fff;
}
:root .editor-styles-wrapper .has-very-light-gray-color,
:root .has-very-light-gray-color {
    color: #eee;
}
:root .editor-styles-wrapper .has-cyan-bluish-gray-color,
:root .has-cyan-bluish-gray-color {
    color: #abb8c3;
}
:root .editor-styles-wrapper .has-very-dark-gray-color,
:root .has-very-dark-gray-color {
    color: #313131;
}
:root .editor-styles-wrapper .has-black-color,
:root .has-black-color {
    color: #000;
}
:root .editor-styles-wrapper .has-vivid-cyan-blue-to-vivid-purple-gradient-background,
:root .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
    background: linear-gradient(135deg, #0693e3, #9b51e0);
}
:root .editor-styles-wrapper .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background,
:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
    background: linear-gradient(135deg, #00d084, #0693e3);
}
:root .editor-styles-wrapper .has-light-green-cyan-to-vivid-green-cyan-gradient-background,
:root .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
    background: linear-gradient(135deg, #7adcb4, #00d082);
}
:root .editor-styles-wrapper .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background,
:root .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
    background: linear-gradient(135deg, #fcb900, #ff6900);
}
:root .editor-styles-wrapper .has-luminous-vivid-orange-to-vivid-red-gradient-background,
:root .has-luminous-vivid-orange-to-vivid-red-gradient-background {
    background: linear-gradient(135deg, #ff6900, #cf2e2e);
}
:root .editor-styles-wrapper .has-very-light-gray-to-cyan-bluish-gray-gradient-background,
:root .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
    background: linear-gradient(135deg, #eee, #a9b8c3);
}
:root .editor-styles-wrapper .has-cool-to-warm-spectrum-gradient-background,
:root .has-cool-to-warm-spectrum-gradient-background {
    background: linear-gradient(135deg, #4aeadc, #9778d1 20%, #cf2aba 40%, #ee2c82 60%, #fb6962 80%, #fef84c);
}
:root .editor-styles-wrapper .has-blush-light-purple-gradient-background,
:root .has-blush-light-purple-gradient-background {
    background: linear-gradient(135deg, #ffceec, #9896f0);
}
:root .editor-styles-wrapper .has-blush-bordeaux-gradient-background,
:root .has-blush-bordeaux-gradient-background {
    background: linear-gradient(135deg, #fecda5, #fe2d2d 50%, #6b003e);
}
:root .editor-styles-wrapper .has-purple-crush-gradient-background,
:root .has-purple-crush-gradient-background {
    background: linear-gradient(135deg, #34e2e4, #4721fb 50%, #ab1dfe);
}
:root .editor-styles-wrapper .has-luminous-dusk-gradient-background,
:root .has-luminous-dusk-gradient-background {
    background: linear-gradient(135deg, #ffcb70, #c751c0 50%, #4158d0);
}
:root .editor-styles-wrapper .has-hazy-dawn-gradient-background,
:root .has-hazy-dawn-gradient-background {
    background: linear-gradient(135deg, #faaca8, #dad0ec);
}
:root .editor-styles-wrapper .has-pale-ocean-gradient-background,
:root .has-pale-ocean-gradient-background {
    background: linear-gradient(135deg, #fff5cb, #b6e3d4 50%, #33a7b5);
}
:root .editor-styles-wrapper .has-electric-grass-gradient-background,
:root .has-electric-grass-gradient-background {
    background: linear-gradient(135deg, #caf880, #71ce7e);
}
:root .editor-styles-wrapper .has-subdued-olive-gradient-background,
:root .has-subdued-olive-gradient-background {
    background: linear-gradient(135deg, #fafae1, #67a671);
}
:root .editor-styles-wrapper .has-atomic-cream-gradient-background,
:root .has-atomic-cream-gradient-background {
    background: linear-gradient(135deg, #fdd79a, #004a59);
}
:root .editor-styles-wrapper .has-nightshade-gradient-background,
:root .has-nightshade-gradient-background {
    background: linear-gradient(135deg, #330968, #31cdcf);
}
:root .editor-styles-wrapper .has-midnight-gradient-background,
:root .has-midnight-gradient-background {
    background: linear-gradient(135deg, #020381, #2874fc);
}
:root .editor-styles-wrapper .has-link-color a,
:root .has-link-color a {
    color: #00e;
    color: #00e;
    color: var(--wp--style--color--link, #00e);
}
.editor-styles-wrapper .has-small-font-size,
.has-small-font-size {
    font-size: 13px;
}
.editor-styles-wrapper .has-normal-font-size,
.editor-styles-wrapper .has-regular-font-size,
.has-normal-font-size,
.has-regular-font-size {
    font-size: 16px;
}
.editor-styles-wrapper .has-medium-font-size,
.has-medium-font-size {
    font-size: 20px;
}
.editor-styles-wrapper .has-large-font-size,
.has-large-font-size {
    font-size: 36px;
}
.editor-styles-wrapper .has-huge-font-size,
.editor-styles-wrapper .has-larger-font-size,
.has-huge-font-size,
.has-larger-font-size {
    font-size: 42px;
}
.has-text-align-center {
    text-align: center;
}
.has-text-align-left {
    text-align: left;
}
.has-text-align-right {
    text-align: right;
}
#end-resizable-editor-section {
    display: none;
}
.aligncenter {
    clear: both;
}

.s_pdf_download_link,
.s_pdf_download_link:hover{
	text-decoration: none;
}

.s_pdf_download_bttn{
	display: block;
	padding: 5px 20px;
	background: #333;
	color: #fff;
	font-size: 18px;
	margin-top: 20px;
	border: 2px solid transparent;
	transition: all ease .25s;
	-webkit-transition: all ease .25s;
}

.s_pdf_download_bttn:hover{
	background: #fff;
	border: 2px solid #333;
	color: #333;
}
/*
Theme Name: Twenty Thirteen
Theme URI: https://wordpress.org/themes/twentythirteen/
Author: the WordPress team
Author URI: https://wordpress.org/
Description: The 2013 theme for WordPress takes us back to the blog, featuring a full range of post formats, each displayed beautifully in their own unique way. Design details abound, starting with a vibrant color scheme and matching header images, beautiful typography and icons, and a flexible layout that looks great on any device, big or small.
Version: 3.1
Requires at least: 3.6
Requires PHP: 5.2.4
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: blog, one-column, two-columns, right-sidebar, custom-header, custom-menu, editor-style, featured-images, footer-widgets, microformats, post-formats, rtl-language-support, sticky-post, translation-ready, accessibility-ready
Text Domain: twentythirteen

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.
*/


/**
 * Table of Contents:
 *
 * 1.0 - Reset
 * 2.0 - Repeatable Patterns
 * 3.0 - Basic Structure
 * 4.0 - Header
 *   4.1 - Site Header
 *   4.2 - Navigation
 * 5.0 - Content
 *   5.1 - Entry Header
 *   5.2 - Entry Meta
 *   5.3 - Entry Content
 *   5.4 - Galleries
 *   5.5 - Post Formats
 *   5.6 - Attachments
 *   5.7 - Post/Paging Navigation
 *   5.8 - Author Bio
 *   5.9 - Archives
 *   5.10 - Search Results/No posts
 *   5.11 - 404
 *   5.12 - Comments
 *   5.13 - Multisite
 * 6.0 - Sidebar
 *   6.1 - Widgets
 * 7.0 - Footer
 * 8.0 - Media Queries
 * 9.0 - Print
 * ----------------------------------------------------------------------------
 */


/**
 * 1.0 Reset
 *
 * Modified from Normalize.css to provide cross-browser consistency and a smart
 * default styling of HTML elements.
 *
 * @see http://git.io/normalize
 * ----------------------------------------------------------------------------
 */

 * {
	box-sizing:         border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden] {
	display: none;
}

html {
	font-size: 100%;
	overflow-y: scroll;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
	font-family: "Source Sans Pro", Helvetica, sans-serif;
}

body {
	color: #141412;
	line-height: 1.5;
	margin: 0;
}

a {
	color: #ca3c08;
	text-decoration: none;
}

a:visited {
	color: #ac0404;
}

a:focus {
	outline: thin dotted;
}

a:active,
a:hover {
	color: #ea9629;
	outline: 0;
}

a:hover {
	text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	font-family: Bitter, Georgia, serif;
	line-height: 1.3;
}

h1 {
	font-size: 48px;
	margin: 33px 0;
}

h2 {
	font-size: 30px;
	margin: 25px 0;
}

h3 {
	font-size: 22px;
	margin: 22px 0;
}

h4 {
	font-size: 20px;
	margin: 25px 0;
}

h5 {
	font-size: 18px;
	margin: 30px 0;
}

h6 {
	font-size: 16px;
	margin: 36px 0;
}

address {
	font-style: italic;
	margin: 0 0 24px;
}

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

mark {
	background: #ff0;
	color: #000;
}

p {
	margin: 0 0 24px;
}

code,
kbd,
pre,
samp {
	font-family: monospace, serif;
	font-size: 14px;
	-webkit-hyphens: none;
	-ms-hyphens:     none;
	hyphens:         none;
}

pre {
	background: #f5f5f5;
	color: #666;
	font-family: monospace;
	font-size: 14px;
	margin: 20px 0;
	overflow: auto;
	padding: 20px;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

blockquote,
q {
	-webkit-hyphens: none;
	-ms-hyphens:     none;
	hyphens:         none;
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

blockquote {
	font-size: 18px;
	font-style: italic;
	font-weight: 300;
	margin: 24px 40px;
}

blockquote blockquote {
	margin-right: 0;
}

blockquote cite,
blockquote small {
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
}

blockquote em,
blockquote i {
	font-style: normal;
	font-weight: 300;
}

blockquote strong,
blockquote b {
	font-weight: 400;
}

small {
	font-size: smaller;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

dl {
	margin: 0 20px;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 0 20px;
}

menu,
ol,
ul {
	margin: 16px 0;
	padding: 0 0 0 40px;
}

ul {
	list-style-type: square;
}

nav ul,
nav ol {
	list-style: none;
	list-style-image: none;
}

li > ul,
li > ol {
	margin: 0;
}

img {
	-ms-interpolation-mode: bicubic;
	border: 0;
	vertical-align: middle;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 0;
}

form {
	margin: 0;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	min-width: inherit;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
}

button,
input,
select,
textarea {
	font-size: 100%;
	margin: 0;
	max-width: 100%;
	vertical-align: baseline;
}

button,
input {
	line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	padding: 0;
}

input[type="search"] {
	-webkit-appearance: textfield;
	padding-right: 2px; /* Don't cut off the webkit search cancel button */
	width: 270px;
}

input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
}

table {
	border-bottom: 1px solid #ededed;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 14px;
	line-height: 2;
	margin: 0 0 20px;
	width: 100%;
}

caption,
th,
td {
	font-weight: normal;
	text-align: left;
}

caption {
	font-size: 16px;
	margin: 20px 0;
}

th {
	font-weight: bold;
	text-transform: uppercase;
}

td {
	border-top: 1px solid #ededed;
	padding: 6px 10px 6px 0;
}

del {
	color: #333;
}

ins {
	background: #fff9c0;
	text-decoration: none;
}

hr {
	/*background: url(images/dotted-line.png) repeat center top; */
	background-size: 4px 4px;
	border: 0;
	height: 1px;
	margin: 0 0 24px;
}


/**
 * 2.0 Repeatable Patterns
 * ----------------------------------------------------------------------------
 */

.genericon:before,
.menu-toggle:after,
.featured-post:before,
.date a:before,
.entry-meta .author a:before,
.format-audio .entry-content:before,
.comments-link a:before,
.tags-links a:first-child:before,
.categories-links a:first-child:before,
.edit-link a:before,
.attachment .entry-title:before,
.attachment-meta:before,
.attachment-meta a:before,
.comment-awaiting-moderation:before,
.comment-reply-link:before,
.comment-reply-login:before,
.comment-reply-title small a:before,
.bypostauthor > .comment-body .fn:before,
.error404 .page-title:before {
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font: normal 16px/1 Genericons;
	vertical-align: text-bottom;
}

/* Clearing floats */
.clear:after,
.attachment .entry-header:after,
.site-footer .widget-area:after,
.entry-content:after,
.page-content:after,
.navigation:after,
.nav-links:after,
.gallery:after,
.comment-form-author:after,
.comment-form-email:after,
.comment-form-url:after,
.comment-body:after {
	clear: both;
}

.clear:before,
.clear:after,
.attachment .entry-header:before,
.attachment .entry-header:after,
.site-footer .widget-area:before,
.site-footer .widget-area:after,
.entry-content:before,
.entry-content:after,
.page-content:before,
.page-content:after,
.navigation:before,
.navigation:after,
.nav-links:before,
.nav-links:after,
.gallery:before,
.gallery:after,
.comment-form-author:before,
.comment-form-author:after,
.comment-form-email:before,
.comment-form-email:after,
.comment-form-url:before,
.comment-form-url:after,
.comment-body:before,
.comment-body:after {
	content: "";
	display: table;
}

/* Assistive text */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	position: absolute !important;
	height: 1px;
	width: 1px;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-weight: bold;
	height: auto;
	line-height: normal;
	padding: 15px 23px 14px;
	position: absolute;
	left: 5px;
	top: 5px;
	text-decoration: none;
	width: auto;
	z-index: 100000; /* Above WP toolbar */
}

/* Form fields, general styles first. */
button,
input,
textarea {
	border: 2px solid #d4d0ba;
	font-family: inherit;
	padding: 5px;
}

input,
textarea {
	color: #141412;
}

input:focus,
textarea:focus {
	border: 2px solid #c3c0ab;
	outline: 0;
}

/* Buttons */
button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
	background: #e05d22; /* Old browsers */ /* Chrome 10+, Safari 5.1+ */
	background:   linear-gradient(to bottom, #e05d22 0%, #d94412 100%); /* W3C */
	border: none;
	border-bottom: 3px solid #b93207;
	border-radius: 2px;
	color: #fff;
	display: inline-block;
	padding: 11px 24px 10px;
	text-decoration: none;
}

button:hover,
button:focus,
input[type="submit"]:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:focus,
input[type="button"]:focus,
input[type="reset"]:focus {
	background: #ed6a31; /* Old browsers */ /* Chrome 10+, Safari 5.1+ */
	background:   linear-gradient(to bottom, #ed6a31 0%, #e55627 100%); /* W3C */
	outline: none;
}

button:active,
input[type="submit"]:active,
input[type="button"]:active,
input[type="reset"]:active {
	background: #d94412; /* Old browsers */ /* Chrome 10+, Safari 5.1+ */
	background:   linear-gradient(to bottom, #d94412 0%, #e05d22 100%); /* W3C */
	border: none;
	border-top: 3px solid #b93207;
	padding: 10px 24px 11px;
}

.post-password-required input[type="submit"] {
	padding: 7px 24px 4px;
	vertical-align: bottom;
}

.post-password-required input[type="submit"]:active {
	padding: 5px 24px 6px;
}

/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
	color: #7d7b6d;
}

:-moz-placeholder {
	color: #7d7b6d;
}

::-moz-placeholder {
	color: #7d7b6d;
}

:-ms-input-placeholder {
	color: #7d7b6d;
}

/*
 * Responsive images
 *
 * Fluid images for posts, comments, and widgets
 */
.entry-content img,
.entry-summary img,
.comment-content img,
.widget img,
.wp-caption {
	max-width: 100%;
}

/* Make sure images with WordPress-added height and width attributes are scaled correctly. */
.entry-content img,
.entry-summary img,
.comment-content img[height],
img[class*="align"],
img[class*="wp-image-"],
img[class*="attachment-"] {
	height: auto;
}

img.size-full,
img.size-large,
img.wp-post-image {
	height: auto;
	max-width: 100%;
}

/* Make sure videos and embeds fit their containers. */
embed,
iframe,
object,
video {
	max-width: 100%;
}

/* Override the Twitter embed fixed width. */
.entry-content .twitter-tweet-rendered {
	max-width: 100% !important;
}

/* Images */
.alignleft {
	float: left;
}

.alignright {
	float: right;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

figure.wp-caption.alignleft,
img.alignleft {
	margin: 5px 20px 5px 0;
}

.wp-caption.alignleft {
	margin: 5px 10px 5px 0;
}

figure.wp-caption.alignright,
img.alignright {
	margin: 5px 0 5px 20px;
}

.wp-caption.alignright {
	margin: 5px 0 5px 10px;
}

img.aligncenter {
	margin: 5px auto;
}

img.alignnone {
	margin: 5px 0;
}

.wp-caption .wp-caption-text,
.entry-caption,
.gallery-caption {
	color: #220e10;
	font-size: 18px;
	font-style: italic;
	font-weight: 300;
	margin: 0 0 24px;
}

div.wp-caption.alignright img[class*="wp-image-"] {
	float: right;
}

div.wp-caption.alignright .wp-caption-text {
	padding-left: 10px;
}

img.wp-smiley,
.rsswidget img {
	border: 0;
	border-radius: 0;
	box-shadow: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

.wp-caption.alignleft + ul,
.wp-caption.alignleft + ol {
	list-style-position: inside;
}


/**
 * 3.0 Basic Structure
 * ----------------------------------------------------------------------------
 */

.site {
	background-color: #fff;
	border-left: 1px solid #f2f2f2;
	border-right: 1px solid #f2f2f2;
	margin: 0 auto;
	max-width: 1600px;
	width: 100%;
}

.site-main {
	position: relative;
}

.site-main .sidebar-container {
	height: 0;
	position: absolute;
	top: 40px;
	width: 100%;
	z-index: 1;
}

.site-main .sidebar-inner {
	margin: 0 auto;
	max-width: 1040px;
}


/**
 * 4.0 Header
 * ----------------------------------------------------------------------------
 */

/**
 * 4.1 Site Header
 * ----------------------------------------------------------------------------
 */

.site-header {
	position: relative;
}

.site-header .home-link {
	color: #141412;
	display: block;
	margin: 0 auto;
	max-width: 1080px;
	min-height: 230px;
	padding: 0 20px;
	text-decoration: none;
	width: 100%;
}

.site-header .site-title:hover {
	text-decoration: underline;
}

.site-title {
	font-size: 60px;
	font-weight: bold;
	line-height: 1;
	margin: 0;
	padding: 58px 0 10px;
}

.site-description {
	font: 300 italic 24px "Source Sans Pro", Helvetica, sans-serif;
	margin: 0;
}


/**
 * 4.2 Navigation
 * ----------------------------------------------------------------------------
 */

.main-navigation {
	clear: both;
	margin: 0 auto;
	max-width: 1080px;
	min-height: 45px;
	position: relative;
}

ul.nav-menu,
div.nav-menu > ul {
	margin: 0;
	padding: 0 40px 0 0;
}

.nav-menu li {
	display: inline-block;
	position: relative;
}

.nav-menu li a {
	color: #141412;
	display: block;
	font-size: 15px;
	line-height: 1;
	padding: 15px 20px;
	text-decoration: none;
}

.nav-menu li:hover > a,
.nav-menu li a:hover,
.nav-menu li:focus > a,
.nav-menu li a:focus {
	background-color: #220e10;
	color: #fff;
}

.nav-menu .sub-menu,
.nav-menu .children {
	background-color: #220e10;
	border: 2px solid #f7f5e7;
	border-top: 0;
	padding: 0;
	position: absolute;
	left: -2px;
	z-index: 99999;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

.nav-menu .sub-menu ul,
.nav-menu .children ul {
	border-left: 0;
	left: 100%;
	top: 0;
}

ul.nav-menu ul a,
.nav-menu ul ul a {
	color: #fff;
	margin: 0;
	width: 200px;
}

ul.nav-menu ul a:hover,
.nav-menu ul ul a:hover,
ul.nav-menu ul a:focus,
.nav-menu ul ul a:focus {
	background-color: #db572f;
}

ul.nav-menu li:hover > ul,
.nav-menu ul li:hover > ul,
ul.nav-menu .focus > ul,
.nav-menu .focus > ul {
	clip: inherit;
	overflow: inherit;
	height: inherit;
	width: inherit;
}

.nav-menu .current_page_item > a,
.nav-menu .current_page_ancestor > a,
.nav-menu .current-menu-item > a,
.nav-menu .current-menu-ancestor > a {
	color: #bc360a;
	font-style: italic;
}

.menu-toggle {
	display: none;
}

/* Navbar */
.navbar {
	background-color: #f7f5e7;
	margin: 0 auto;
	max-width: 1600px;
	width: 100%;
}

.site-header .search-form {
	position: absolute;
	right: 20px;
	top: 1px;
}

.site-header .search-field {
	background-color: transparent;
	/*background-image: url(images/search-icon.png); */
	background-position: 5px center;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	border: none;
	cursor: pointer;
	height: 37px;
	margin: 3px 0;
	padding: 0 0 0 34px;
	position: relative;
	transition:         width 400ms ease, background 400ms ease;
	width: 1px;
}

.site-header .search-field:focus {
	background-color: #fff;
	border: 2px solid #c3c0ab;
	cursor: text;
	outline: 0;
	width: 230px;
}


/**
 * 5.0 Content
 * ----------------------------------------------------------------------------
 */

.hentry {
	padding: 40px 0;
}

.entry-header,
.entry-content,
.entry-summary,
.entry-meta {
	margin: 0 auto;
	max-width: 604px;
	width: 100%;
}

.sidebar .entry-header,
.sidebar .entry-content,
.sidebar .entry-summary,
.sidebar .entry-meta {
	max-width: 1040px;
	padding: 0 376px 0 60px;
}


/**
 * 5.1 Entry Header
 * ----------------------------------------------------------------------------
 */

.sidebar .entry-header .entry-meta {
	padding: 0;
}

.entry-thumbnail img {
	display: block;
	margin: 0 auto 10px;
}

.entry-header {
	margin-bottom: 30px;
}

.entry-title {
	font-weight: normal;
	margin: 0 0 5px;
}

.entry-title a {
	color: #141412;
}

.entry-title a:hover {
	color: #ea9629;
}


/**
 * 5.2 Entry Meta
 * ----------------------------------------------------------------------------
 */

.entry-meta {
	clear: both;
	font-size: 14px;
}

.entry-meta a {
	color: #bc360a;
}

.entry-meta a:hover {
	color: #bc360a;
}

.entry-meta > span {
	margin-right: 20px;
}

.entry-meta > span:last-child {
	margin-right: 0;
}

.featured-post:before {
	content: "\F308";
	margin-right: 2px;
}

.entry-meta .date a:before {
	content: "\F303";
}

.comments-link a:before {
	content: "\F300";
	margin-right: 2px;
	position: relative;
	top: -1px;
}

.entry-meta .author a:before {
	content: "\F304";
	position: relative;
	top: -1px;
}

.categories-links a:first-child:before {
	content: "\F301";
}

.tags-links a:first-child:before {
	content: "\F302";
	position: relative;
	top: -1px;
}

.edit-link a:before {
	content: "\F411";
	position: relative;
	top: -1px;
}

.single-author .entry-meta .author,
.sticky.format-standard .entry-meta .date,
.sticky.format-audio .entry-meta .date,
.sticky.format-chat .entry-meta .date,
.sticky.format-image .entry-meta .date,
.sticky.format-gallery .entry-meta .date {
	display: none;
}


/**
 * 5.3 Entry Content
 * ----------------------------------------------------------------------------
 */

.entry-content {
	-webkit-hyphens: auto;
	-ms-hyphens:     auto;
	hyphens:         auto;
	word-wrap: break-word;
}

.entry-content a,
.comment-content a {
	color: #bc360a;
}

.entry-content a:hover,
.comment-content a:hover {
	color: #ea9629;
}

.entry-content .more-link {
	white-space: nowrap;
}

.entry-content blockquote {
	font-size: 24px;
}

.entry-content blockquote cite,
.entry-content blockquote small {
	font-size: 16px;
}

.entry-content img.alignleft,
.entry-content .wp-caption.alignleft {
	margin-left: -60px;
}

.entry-content img.alignright,
.entry-content .wp-caption.alignright {
	margin-right: -60px;
}

footer.entry-meta {
	margin-top: 24px;
}

.format-standard footer.entry-meta {
	margin-top: 0;
}

/* Page links */
.page-links {
	clear: both;
	font-size: 16px;
	font-style: italic;
	font-weight: normal;
	line-height: 2.2;
	margin: 20px 0;
	text-transform: uppercase;
}

.page-links a,
.page-links > span {
	background: #fff;
	border: 1px solid #fff;
	padding: 5px 10px;
	text-decoration: none;
}

.format-status .entry-content .page-links a,
.format-gallery .entry-content .page-links a,
.format-chat .entry-content .page-links a,
.format-quote .entry-content .page-links a,
.page-links a {
	background: #e63f2a;
	border: 1px solid #e63f2a;
	color: #fff;
}

.format-gallery .entry-content .page-links a:hover,
.format-audio .entry-content .page-links a:hover,
.format-status .entry-content .page-links a:hover,
.format-video .entry-content .page-links a:hover,
.format-chat .entry-content .page-links a:hover,
.format-quote .entry-content .page-links a:hover,
.page-links a:hover {
	background: #fff;
	color: #e63f2a;
}

.format-status .entry-content .page-links > span,
.format-quote .entry-content .page-links > span {
	background: none;
}

.page-links .page-links-title {
	background: transparent;
	border: none;
	margin-right: 20px;
	padding: 0;
}

/* Mediaelements */
.hentry .mejs-mediaelement,
.widget .mejs-mediaelement,
.hentry .mejs-container .mejs-controls,
.widget .mejs-container .mejs-controls {
	background: #220e10;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-loaded,
.widget .mejs-controls .mejs-time-rail .mejs-time-loaded,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
.widget .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
	background: #fff;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-current,
.widget .mejs-controls .mejs-time-rail .mejs-time-current {
	background: #ea9629;
}

.hentry .mejs-controls .mejs-time-rail .mejs-time-total,
.widget .mejs-controls .mejs-time-rail .mejs-time-total,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.widget .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
	background: #595959;
}

.hentry .mejs-controls .mejs-time-rail span,
.widget .mejs-controls .mejs-time-rail span,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.widget .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.hentry .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
.widget .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
	border-radius: 0;
}


/**
 * 5.4 Galleries
 * ----------------------------------------------------------------------------
 */

.gallery {
	margin-bottom: 20px;
	margin-left: -4px;
}

.gallery-item {
	float: left;
	margin: 0 4px 4px 0;
	overflow: hidden;
	position: relative;
}

.gallery-columns-1.gallery-size-medium,
.gallery-columns-1.gallery-size-thumbnail,
.gallery-columns-2.gallery-size-thumbnail,
.gallery-columns-3.gallery-size-thumbnail {
	display: table;
	margin: 0 auto 20px;
}

.gallery-columns-1 .gallery-item,
.gallery-columns-2 .gallery-item,
.gallery-columns-3 .gallery-item {
	text-align: center;
}

.gallery-columns-4 .gallery-item {
	max-width: 23%;
	max-width:         calc(25% - 4px);
}

.gallery-columns-5 .gallery-item {
	max-width: 19%;
	max-width:         calc(20% - 4px);
}

.gallery-columns-6 .gallery-item {
	max-width: 15%;
	max-width:         calc(16.7% - 4px);
}

.gallery-columns-7 .gallery-item {
	max-width: 13%;
	max-width:         calc(14.28% - 4px);
}

.gallery-columns-8 .gallery-item {
	max-width: 11%;
	max-width:         calc(12.5% - 4px);
}

.gallery-columns-9 .gallery-item {
	max-width: 9%;
	max-width:         calc(11.1% - 4px);
}

.gallery-columns-1 .gallery-item:nth-of-type(1n),
.gallery-columns-2 .gallery-item:nth-of-type(2n),
.gallery-columns-3 .gallery-item:nth-of-type(3n),
.gallery-columns-4 .gallery-item:nth-of-type(4n),
.gallery-columns-5 .gallery-item:nth-of-type(5n),
.gallery-columns-6 .gallery-item:nth-of-type(6n),
.gallery-columns-7 .gallery-item:nth-of-type(7n),
.gallery-columns-8 .gallery-item:nth-of-type(8n),
.gallery-columns-9 .gallery-item:nth-of-type(9n) {
	margin-right: 0;
}

.gallery-columns-1.gallery-size-medium figure.gallery-item:nth-of-type(1n+1),
.gallery-columns-1.gallery-size-thumbnail figure.gallery-item:nth-of-type(1n+1),
.gallery-columns-2.gallery-size-thumbnail figure.gallery-item:nth-of-type(2n+1),
.gallery-columns-3.gallery-size-thumbnail figure.gallery-item:nth-of-type(3n+1),
.widget-area .gallery-columns-2 figure.gallery-item:nth-of-type(2n+1),
.widget-area .gallery-columns-3 figure.gallery-item:nth-of-type(3n+1),
.widget-area .gallery-columns-4 figure.gallery-item:nth-of-type(4n+1),
.widget-area .gallery-columns-5 figure.gallery-item:nth-of-type(5n+1),
.widget-area .gallery-columns-6 figure.gallery-item:nth-of-type(6n+1),
.widget-area .gallery-columns-7 figure.gallery-item:nth-of-type(7n+1),
.widget-area .gallery-columns-8 figure.gallery-item:nth-of-type(8n+1),
.widget-area .gallery-columns-9 figure.gallery-item:nth-of-type(9n+1) {
	clear: left;
}

.gallery-caption {
	background-color: rgba(0, 0, 0, 0.7);
	box-sizing: border-box;
	color: #fff;
	font-size: 14px;
	line-height: 1.3;
	margin: 0;
	max-height: 50%;
	opacity: 0;
	padding: 2px 8px;
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: left;
	transition:         opacity 400ms ease;
	width: 100%;
}

.gallery-caption:before {
	box-shadow: 0 -10px 15px #000 inset;
	content: "";
	height: 100%;
	min-height: 49px;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

.gallery-item:hover .gallery-caption {
	opacity: 1;
}

.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption,
.widget-area .gallery-columns-2 .gallery-caption,
.widget-area .gallery-columns-3 .gallery-caption,
.widget-area .gallery-columns-4 .gallery-caption,
.widget-area .gallery-columns-5 .gallery-caption,
.widget-area .gallery-columns-6 .gallery-caption,
.widget-area .gallery-columns-7 .gallery-caption,
.widget-area .gallery-columns-8 .gallery-caption,
.widget-area .gallery-columns-9 .gallery-caption {
	display: none;
}


/**
 * 5.5 Post Formats
 * ----------------------------------------------------------------------------
 */

/* Aside */
.format-aside {
	background-color: #f7f5e7;
}

.blog .format-aside:first-of-type,
.single .format-aside:first-of-type,
.format-aside + .format-aside,
.format-aside + .format-link,
.format-link + .format-aside {
	box-shadow: inset 0 2px 2px rgba(173, 165, 105, 0.2);
}

.format-aside .entry-meta {
	margin-top: 0;
}

.format-aside blockquote {
	font-size: 100%;
	font-weight: normal;
}

.format-aside cite {
	font-size: 100%;
	text-transform: none;
}

.format-aside cite:before {
	content: "\2014";
	margin-right: 5px;
}

/* Audio */
.format-audio {
	background-color: #db572f;
}

.format-audio .entry-title {
	font-size: 28px;
	font-weight: bold;
}

.format-audio .entry-content:before {
	content: "\F109";
	float: left;
	font-size: 64px;
	position: relative;
	top: 4px;
}

.format-audio .entry-content a,
.format-audio .entry-meta a,
.format-audio .entry-content a:hover,
.format-audio .entry-meta a:hover {
	color: #fbfaf3;
}

.format-audio .audio-content {
	/*background: url(images/dotted-line.png) repeat-y left top; */
	background-size: 4px 4px;
	float: right;
	padding-left: 35px;
	width: 80%;
	width:         calc(100% - 85px);
}

.format-audio .wp-audio-shortcode {
	height: 30px !important; /* Override mediaelement.js style */
	margin: 20px 0;
	max-width: 400px !important; /* Override mediaelement.js style */
}

.format-audio audio {
	max-width: 100% !important; /* Avoid player width overflow. */
}

/* Chat */
.format-chat {
	background-color: #eadaa6;
}

.format-chat .entry-title {
	font-size: 28px;
	font-weight: bold;
}

.format-chat .entry-meta a,
.format-chat .entry-content a {
	color: #722d19;
}

.format-chat .entry-meta .date a:before {
	content: "\F108";
	margin-right: 2px;
}

.format-chat .entry-meta .author {
	display: none;
}

.format-chat .chat {
	margin: 0;
}

.format-chat .chat .chat-timestamp {
	color: #722d19;
	float: right;
	font-size: 12px;
	font-weight: normal;
	margin: 5px 10px 0;
}

.format-chat .chat .fn {
	font-style: normal;
}

/* Gallery */
.format-gallery {
	background-color: #fbca3c;
}

.format-gallery .entry-header {
	margin-bottom: 15px;
}

.format-gallery .entry-title {
	font-size: 50px;
	font-weight: 400;
	margin: 0;
}

.format-gallery .entry-meta a,
.format-gallery .entry-content a {
	color: #722d19;
}

/* Image */
.format-image .entry-title {
	font-size: 28px;
	font-weight: bold;
}

.format-image .categories-links,
.format-image .tags-links {
	display: none;
}

/* Link */
.format-link {
	background-color: #f7f5e7;
}

.blog .format-link:first-of-type,
.single .format-link:first-of-type {
	box-shadow: inset 0 2px 2px rgba(173, 165, 105, 0.2);
}

.format-link .entry-header,
.format-link .entry-content p:last-child {
	margin-bottom: 0;
}

.format-link .entry-title {
	color: #ca3c08;
	display: inline;
	font: 300 italic 20px "Source Sans Pro", Helvetica, sans-serif;
	margin-right: 20px;
}

.format-link .entry-title a {
	color: #bc360a;
}

.format-link div.entry-meta {
	display: inline;
}

/* Standard */
.format-standard .wp-video,
.format-standard .wp-audio-shortcode,
.format-audio .wp-audio-shortcode,
.format-standard .video-player {
	margin-bottom: 24px;
}

/* Quote */
.format-quote {
	background-color: #210d10;
}

.format-quote .entry-content,
.format-quote .entry-meta {
	color: #f7f5e7;
}

.format-quote .entry-content blockquote {
	font-size: 28px;
	margin: 0;
}

.format-quote .entry-content a,
.format-quote .entry-meta a,
.format-quote .linked {
	color: #e63f2a;
}

.format-quote .entry-content cite a {
	border-bottom: 1px dotted #fff;
	color: #fff;
}

.format-quote .entry-content cite a:hover {
	text-decoration: none;
}

.format-quote blockquote small,
.format-quote blockquote cite {
	display: block;
	font-size: 16px;
}

.format-quote blockquote {
	font-style: italic;
	font-weight: 300;
	padding-left: 75px;
	position: relative;
}

.format-quote blockquote:before {
	content: '\201C';
	font-size: 140px;
	font-weight: 400;
	line-height: .8;
	padding-right: 25px;
	position: absolute;
	left: -15px;
	top: -3px;
}

.format-quote .entry-meta .author {
	display: none;
}

/* Status */
.format-status {
	background-color: #722d19;
	padding: 0;
}

.format-status .entry-content,
.format-status .entry-meta {
	padding-left: 35px;
	position: relative;
}

.format-status .entry-content a {
	color: #eadaa6;
}

.format-status .entry-meta a {
	color: #f7f5e7;
}

.sidebar .format-status .entry-content,
.sidebar .format-status .entry-meta {
	padding-left: 95px;
}

.format-status .entry-content:before,
.format-status .entry-meta:before {
	/* background: url(images/dotted-line.png) repeat-y left bottom; */
	background-size: 4px 4px;
	content: "";
	display: block;
	height: 100%;
	position: absolute;
	left: 10px;
	top: 0;
	width: 1px;
}

.sidebar .format-status .entry-content:before,
.sidebar .format-status .entry-meta:before {
	left: 70px;
}

.format-status .categories-links,
.format-status .tags-links {
	display: none;
}

/* Ensures the dots in the dot background are in lockstep. */
.format-status .entry-meta:before {
	background-position: left top;
}

.format-status .entry-content {
	color: #f7f5e7;
	font-size: 24px;
	font-style: italic;
	font-weight: 300;
	padding-bottom: 30px;
	padding-top: 40px;
	position: relative;
}

.format-status .entry-content p:first-child:before {
	background-color: rgba(0, 0, 0, 0.65);
	content: "";
	height: 3px;
	margin-top: 13px;
	position: absolute;
	left: 4px;
	width: 13px;
}

.sidebar .format-status .entry-content > p:first-child:before {
	left: 64px;
}

.format-status .entry-content p:last-child {
	margin-bottom: 0;
}

.format-status .entry-meta {
	margin-top: 0;
	padding-bottom: 40px;
}

.format-status .entry-meta .date a:before {
	content: "\F105";
}

/* Video */
.format-video {
	background-color: #db572f;
}

.format-video .entry-content a,
.format-video .entry-meta a,
.format-video .entry-content a:hover,
.format-video .entry-meta a:hover {
	color: #fbfaf3;
}

.format-video .entry-title {
	font-size: 50px;
	font-weight: 400;
}

.format-video .entry-meta {
	color: #220e10;
}


/**
 * 5.6 Attachments
 * ----------------------------------------------------------------------------
 */

.attachment .hentry {
	background-color: #e8e5ce;
	margin: 0;
	padding: 0;
}

.attachment .entry-header {
	margin-bottom: 0;
	max-width: 1040px;
	padding: 30px 0;
}

.attachment .entry-title {
	display: inline-block;
	float: left;
	font: 300 italic 30px "Source Sans Pro", Helvetica, sans-serif;
	margin: 0;
}

.attachment .entry-title:before {
	content: "\F416";
	font-size: 32px;
	margin-right: 10px;
}

.attachment .entry-meta {
	clear: none;
	color: inherit;
	float: right;
	max-width: 604px;
	padding: 9px 0 0;
	text-align: right;
}

.hentry.attachment:not(.image-attachment) .entry-meta {
	max-width: 104px;
}

.attachment footer.entry-meta {
	display: none;
}

.attachment-meta:before {
	content: "\F307";
}

.full-size-link a:before {
	content: "\F402";
}

.full-size-link:before {
	content: none;
}

.attachment .entry-meta a,
.attachment .entry-meta .edit-link:before,
.attachment .full-size-link:before {
	color: #ca3c08;
}

.attachment .entry-content {
	background-color: #fff;
	max-width: 100%;
	padding: 40px 0;
}

.image-navigation {
	margin: 0 auto;
	max-width: 1040px;
	position: relative;
}

.image-navigation a:hover {
	text-decoration: none;
}

.image-navigation .nav-previous,
.image-navigation .nav-next {
	position: absolute;
	top: 50px;
}

.image-navigation .nav-previous {
	left: 0;
}

.image-navigation .nav-next {
	right: 0;
}

.image-navigation .meta-nav {
	font-size: 32px;
	font-weight: 300;
	vertical-align: -4px;
}

.attachment .entry-attachment,
.attachment .type-attachment p {
	margin: 0 auto;
	max-width: 724px;
	text-align: center;
}

.attachment .entry-attachment .attachment {
	display: inline-block;
}

.attachment .entry-caption {
	text-align: left;
}

.attachment .entry-description {
	margin: 20px auto 0;
	max-width: 604px;
}

.attachment .entry-caption p:last-child,
.attachment .entry-description p:last-child {
	margin: 0;
}

.attachment .site-main .sidebar-container {
	display: none;
}

.attachment .entry-content .mejs-audio {
	max-width: 400px;
	margin: 0 auto;
}

.attachment .entry-content .wp-video {
	margin: 0 auto;
}

.attachment .entry-content .mejs-container {
	margin-bottom: 24px;
}

/**
 * 5.7 Post/Paging Navigation
 * ----------------------------------------------------------------------------
 */

.navigation .nav-previous {
	float: left;
}

.navigation .nav-next {
	float: right;
}

.navigation a {
	color: #bc360a;
}

.navigation a:hover {
	color: #ea9629;
	text-decoration: none;
}

.paging-navigation {
	background-color: #e8e5ce;
	padding: 40px 0;
}

.paging-navigation .nav-links {
	margin: 0 auto;
	max-width: 604px;
	width: 100%;
}

.sidebar .paging-navigation .nav-links {
	max-width: 1040px;
	padding: 0 376px 0 60px;
}

.paging-navigation .nav-next {
	padding: 13px 0;
}

.paging-navigation a {
	font-size: 22px;
	font-style: italic;
	font-weight: 300;
}

.paging-navigation .meta-nav {
	background-color: #e63f2a;
	border-radius: 50%;
	color: #fff;
	display: inline-block;
	font-size: 26px;
	padding: 3px 0 8px;
	text-align: center;
	width: 50px;
}

.paging-navigation .nav-previous .meta-nav {
	margin-right: 10px;
	padding: 17px 0 23px;
	width: 80px;
}

.paging-navigation .nav-next .meta-nav {
	margin-left: 10px;
}

.paging-navigation a:hover .meta-nav {
	background-color: #ea9629;
	text-decoration: none;
}

.post-navigation {
	background-color: #fff;
	color: #ca3c08;
	font-size: 20px;
	font-style: italic;
	font-weight: 300;
	padding: 20px 0;
}

.post-navigation .nav-links {
	margin: 0 auto;
	max-width: 1040px;
}

.sidebar .post-navigation .nav-links {
	padding: 0 376px 0 60px;
}

.post-navigation a[rel="next"] {
	float: right;
	text-align: right;
}


/**
 * 5.8 Author Bio
 * ----------------------------------------------------------------------------
 */

.author-info {
	margin: 0 auto;
	max-width: 604px;
	padding: 30px 0 10px;
	text-align: left; /* gallery & video post formats */
	width: 100%;
}

.author.sidebar .author-info {
	max-width: 1040px;
	padding: 30px 376px 10px 60px;
}

.single .author-info {
	padding: 50px 0 0;
}

.author-avatar .avatar {
	float: left;
	margin: 0 30px 30px 0;
}

.single-format-status .author-description {
	color: #f7f5e7;
}

.author-description .author-title {
	clear: none;
	font: 300 italic 20px "Source Sans Pro", Helvetica, sans-serif;
	margin: 0 0 8px;
}

.author-link {
	color: #ca3c08;
	margin-left: 2px;
}

.author.archive .author-link {
	display: none;
}


/**
 * 5.9 Archives
 * ----------------------------------------------------------------------------
 */

.archive-header {
	background-color: #e8e5ce;
}

.archive-title,
.archive-meta {
	font: 300 italic 30px "Source Sans Pro", Helvetica, sans-serif;
	margin: 0 auto;
	max-width: 1040px;
	padding: 30px 0;
	width: 100%;
}

.archive-meta {
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
	margin-top: -15px;
	padding: 0 0 11px;
}

.sidebar .archive-meta {
	padding-right: 316px;
}


/**
 * 5.10 Search Results/No posts
 * ----------------------------------------------------------------------------
 */

.page-header {
	background-color: #e8e5ce;
}

.page-title {
	font: 300 italic 30px "Source Sans Pro", Helvetica, sans-serif;
	margin: 0 auto;
	max-width: 1040px;
	padding: 30px 0;
	width: 100%;
}

.page-content {
	margin: 0 auto;
	max-width: 604px;
	padding: 40px 0;
	width: 100%;
}

.sidebar .page-content {
	margin: 0 auto;
	max-width: 1040px;
	padding: 40px 376px 40px 60px;
}


/**
 * 5.11 404
 * ----------------------------------------------------------------------------
 */

.error404 .page-header {
	background-color: #fff;
}

.error404 .page-title {
	line-height: 0.6;
	margin: 0;
	padding: 300px;
	position: relative;
	text-align: center;
	width: auto;
}

.error404 .page-title:before {
	color: #e8e5ce;
	content: "\F423";
	font-size: 964px;
	line-height: 0.6;
	overflow: hidden;
	position: absolute;
	left: 7px;
	top: 28px;
}

.error404 .page-wrapper {
	background-color: #e8e5ce;
}

.error404 .page-header,
.error404 .page-content {
	margin: 0 auto;
	max-width: 1040px;
	padding-bottom: 40px;
	width: 100%;
}


/**
 * 5.12 Comments
 * ----------------------------------------------------------------------------
 */

.comments-title,
.comment-list,
.comment-reply-title,
.must-log-in,
.comment-respond .comment-form,
.comment-respond iframe {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 604px;
	width: 100%;
}

.sidebar .comments-title,
.sidebar .comment-list,
.sidebar .must-log-in,
.sidebar .comment-reply-title,
.sidebar .comment-navigation,
.sidebar .comment-respond .comment-form {
	max-width: 1040px;
	padding-left: 60px;
	padding-right: 376px;
}

.comments-title {
	font: 300 italic 28px "Source Sans Pro", Helvetica, sans-serif;
}

.comment-list,
.comment-list .children {
	list-style-type: none;
	padding: 0;
}

.comment-list .children {
	margin-left: 20px;
}

.comment-list > li:after,
.comment-list .children > li:before {
	/* background: url(images/dotted-line.png) repeat left top; */
	background-size: 4px 4px;
	content: "";
	display: block;
	height: 1px;
	width: 100%;
}

.comment-list > li:last-child:after {
	display: none;
}

.comment-body {
	padding: 24px 0;
	position: relative;
}

.comment-author {
	float: left;
	max-width: 74px;
}

.comment-author .avatar {
	display: block;
	margin-bottom: 10px;
}

.comment-author .fn {
	word-wrap: break-word;
}

.comment-author .fn,
.comment-author .url,
.comment-reply-link,
.comment-reply-login {
	color: #bc360a;
	font-size: 14px;
	font-style: normal;
	font-weight: normal;
}

.says {
	display: none;
}

.no-avatars .comment-author {
	margin: 0 0 5px;
	max-width: 100%;
	position: relative;
}

.no-avatars .comment-metadata,
.no-avatars .comment-content,
.no-avatars .comment-list .reply {
	width: 100%;
}

.bypostauthor > .comment-body .fn:before {
	content: "\F408";
	vertical-align: text-top;
}

.comment-list .edit-link {
	margin-left: 20px;
}

.comment-metadata,
.comment-awaiting-moderation,
.comment-content,
.comment-list .reply {
	float: right;
	width: 79%;
	width:         calc(100% - 124px);
	word-wrap: break-word;
}

.comment-meta,
.comment-meta a {
	color: #a2a2a2;
	font-size: 13px;
}

.comment-meta a:hover {
	color: #ea9629;
}

.comment-metadata {
	margin-bottom: 20px;
}

.ping-meta {
	color: #a2a2a2;
	font-size: 13px;
	line-height: 2;
}

.comment-awaiting-moderation {
	color: #a2a2a2;
}

.comment-awaiting-moderation:before {
	content: "\F414";
	margin-right: 5px;
	position: relative;
	top: -2px;
}

.comment-reply-link:before,
.comment-reply-login:before {
	content: "\F412";
	margin-right: 3px;
}

/* Comment form */
.comment-respond {
	background-color: #f7f5e7;
	padding: 30px 0;
}

.comment .comment-respond {
	margin-bottom: 20px;
	padding: 20px;
}

.comment-reply-title {
	font: 300 italic 28px "Source Sans Pro", Helvetica, sans-serif;
}

.comment-reply-title small a {
	color: #131310;
	display: inline-block;
	float: right;
	height: 16px;
	overflow: hidden;
	width: 16px;
}

.comment-reply-title small a:hover {
	color: #ed331c;
	text-decoration: none;
}

.comment-reply-title small a:before {
	content: "\F406";
	vertical-align: top;
}

.sidebar .comment-list .comment-reply-title,
.sidebar .comment-list .comment-respond .comment-form {
	padding: 0;
}

.comment-form .comment-notes {
	margin-bottom: 15px;
}

.comment-form .comment-form-author,
.comment-form .comment-form-email,
.comment-form .comment-form-url {
	margin-bottom: 8px;
}

.comment-form [for="author"],
.comment-form [for="email"],
.comment-form [for="url"],
.comment-form [for="comment"] {
	float: left;
	padding: 5px 0;
	width: 120px;
}

.comment-form .required {
	color: #ed331c;
}

.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"] {
	max-width: 270px;
	width: 60%;
}

.comment-form textarea {
	width: 100%;
}

.comment-form #wp-comment-cookies-consent {
	margin: 0 10px 0 0;
}

.form-allowed-tags,
.form-allowed-tags code {
	color: #686758;
	font-size: 12px;
}

.form-allowed-tags code {
	font-size: 10px;
	margin-left: 3px;
}

.comment-list .pingback,
.comment-list .trackback {
	padding-top: 24px;
}

.comment-navigation {
	font-size: 20px;
	font-style: italic;
	font-weight: 300;
	margin: 0 auto;
	max-width: 604px;
	padding: 20px 0 30px;
	width: 100%;
}

.no-comments {
	background-color: #f7f5e7;
	font-size: 20px;
	font-style: italic;
	font-weight: 300;
	margin: 0;
	padding: 40px 0;
	text-align: center;
}

.sidebar .no-comments {
	padding-left: 60px;
	padding-right: 376px;
}


/**
 * 5.13 Multisite
 * ----------------------------------------------------------------------------
 */

.site-main .mu_register {
	margin: 0 auto;
	max-width: 604px;
	width: 100%;
}

.mu_alert {
	margin-top: 25px;
}

.site-main .mu_register input[type="submit"],
.site-main .mu_register #blog_title,
.site-main .mu_register #user_email,
.site-main .mu_register #blogname,
.site-main .mu_register #user_name {
	font-size: inherit;
	width: 270px;
}

.site-main .mu_register input[type="submit"] {
	width: auto;
}


/**
 * 6.0 Sidebar
 * ----------------------------------------------------------------------------
 */

.site-main .widget-area {
	float: right;
	width: 300px;
}


/**
 * 6.1 Widgets
 * ----------------------------------------------------------------------------
 */

.widget {
	background-color: rgba(247, 245, 231, 0.7);
	font-size: 14px;
	-webkit-hyphens: auto;
	-ms-hyphens:     auto;
	hyphens:         auto;
	margin: 0 0 24px;
	padding: 20px;
	word-wrap: break-word;
}

.widget .widget-title {
	font: 300 italic 20px "Source Sans Pro", Helvetica, sans-serif;
	margin: 0 0 10px;
}

.widget ul,
.widget ol {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.widget li {
	padding: 5px 0;
}

.widget .children li:last-child {
	padding-bottom: 0;
}

.widget li > ul,
.widget li > ol {
	margin-left: 20px;
}

.widget a {
	color: #bc360a;
}

.widget a:hover {
	color: #ea9629;
}

/* Search widget */
.search-form .search-submit {
	display: none;
}

/* RSS Widget */
.widget_rss .rss-date {
	display: block;
}

.widget_rss .rss-date,
.widget_rss li > cite {
	color: #a2a2a2;
}

/* Calendar Widget */
.widget_calendar table,
.widget_calendar td {
	border: 0;
	border-collapse: separate;
	border-spacing: 1px;
}

.widget_calendar caption {
	font-size: 14px;
	margin: 0;
}

.widget_calendar th,
.widget_calendar td {
	padding: 0;
	text-align: center;
}

.widget_calendar a {
	display: block;
}

.widget_calendar a:hover {
	background-color: rgba(0, 0, 0, 0.15);
}

.widget_calendar tbody td {
	background-color: rgba(255, 255, 255, 0.5);
}

.site-footer .widget_calendar tbody td {
	background-color: rgba(255, 255, 255, 0.05);
}

.widget_calendar tbody .pad, .site-footer .widget_calendar tbody .pad {
	background-color: transparent;
}

.wp-calendar-table {
	margin-bottom: 0;
}

.wp-calendar-nav {
	display: table;
	line-height: 2;
	margin-bottom: 20px;
	width: 100%;
}

.wp-calendar-nav span {
	display: table-cell;
	text-align: center;
}

.wp-calendar-nav-prev,
.wp-calendar-nav-next {
	width: 40%;
}

/* Text widget */
.widget_text ul,
.widget_text ol {
	padding-left: 20px;
	margin-bottom: 16px;
}

.widget_text li:last-child {
	padding-bottom: 0;
}

.widget_text li > ul,
.widget_text li > ol {
	margin-bottom: 0;
}

.widget_text ul {
	list-style: square;
}

.widget_text ol {
	list-style: decimal;
}

/* Tag Cloud widget */
.tagcloud ul {
	list-style-type: none;
}

.tagcloud ul li {
	display: inline-block;
	padding: 0;
}

/* Gallery Widget */
.widget .gallery-columns-1 .gallery-item {
	max-width: 100%;
}

.widget .gallery-columns-2 .gallery-item {
	max-width: 49%;
	max-width:         calc(50% - 4px);
}

.widget .gallery-columns-3 .gallery-item {
	max-width: 22%;
	max-width:         calc(33% - 4px);
}

/**
 * 7.0 Footer
 * ----------------------------------------------------------------------------
 */

.site-footer {
	background-color: #e8e5ce;
	color: #686758;
	font-size: 14px;
	text-align: center;
}

.site-footer .widget-area,
.sidebar .site-footer {
	text-align: left;
}

.site-footer a {
	color: #686758;
}

.site-footer .sidebar-container {
	background-color: #220e10;
	padding: 20px 0;
}

.site-footer .widget-area {
	margin: 0 auto;
	max-width: 1040px;
	width: 100%;
}

.sidebar .site-footer .widget-area {
	max-width: 724px;
	position: relative;
	left: -158px;
}

.site-footer .widget {
	background: transparent;
	color: #fff;
	float: left;
	margin-right: 20px;
	width: 245px;
}

.sidebar .site-footer .widget {
	width: 228px;
}

.sidebar .site-footer .widget:nth-of-type(4),
.sidebar .site-footer .widget:nth-of-type(3) {
	margin-right: 0;
}

.site-footer .widget a {
	color: #e6402a;
}

.site-footer .widget-title,
.site-footer .widget-title a,
.site-footer .wp-caption-text {
	color: #fff;
}

.site-info {
	margin: 0 auto;
	max-width: 1040px;
	padding: 30px 0;
	width: 100%;
}

#wpstats {
	display: block;
	margin: -10px auto 0;
}

.site-footer span[role=separator] {
	padding: 0 0.25em 0 0.5em;
}

.site-footer span[role=separator]::before {
	content: '/';
}

/**
 * 8.0 Media Queries
 * ----------------------------------------------------------------------------
 */

/* Does the same thing as <meta name="viewport" content="width=device-width">,
 * but in the future W3C standard way. -ms- prefix is required for IE10+ to
 * render responsive styling in Windows 8 "snapped" views; IE10+ does not honor
 * the meta tag. See https://core.trac.wordpress.org/ticket/25888.
 */
@-ms-viewport {
	width: device-width;
}
@viewport {
	width: device-width;
}

@media (max-width: 1599px) {
	.site {
		border: 0;
	}
}

@media (max-width: 1069px) {
	.sidebar img.alignleft,
	.sidebar .wp-caption.alignleft {
		margin-left: 0;
	}

	.sidebar img.alignright,
	.sidebar .wp-caption.alignright {
		margin-right: 0;
	}

	.error404 .page-header {
		margin-left: auto;
		max-width: 604px;
		width: 100%;
	}

	.archive-header,
	.search .page-header,
	.archive .page-header,
	.blog .page-header,
	.error404 .page-content,
	.search .page-content,
	.archive .page-content,
	.attachment .entry-header,
	.attachment .entry-content,
	.post-navigation .nav-links,
	.sidebar .site-info,
	.site-footer .widget-area {
		padding-left: 20px;
		padding-right: 20px;
	}

	.error404 .page-title {
		font-size: 24px;
		padding: 180px;
	}

	.error404 .page-title:before {
		font-size: 554px;
	}

	.attachment .image-navigation {
		max-width: 724px;
	}

	.image-navigation .nav-previous,
	.image-navigation .nav-next {
		position: static;
	}

	.site-main .widget-area {
		margin-right: 60px;
	}
}

@media (max-width: 999px) {
	.sidebar .entry-header,
	.sidebar .entry-content,
	.sidebar .entry-summary,
	.sidebar .entry-meta,
	.sidebar .comment-list,
	.sidebar .comment-reply-title,
	.sidebar .comment-navigation,
	.sidebar .comment-respond .comment-form,
	.sidebar .featured-gallery,
	.sidebar .post-navigation .nav-links,
	.author.sidebar .author-info {
		max-width: 604px;
		padding-left: 0;
		padding-right: 0;
	}

	.sidebar .site-info,
	.search.sidebar .page-content,
	.blog.sidebar .page-content,
	.attachment .entry-header,
	.sidebar .comments-title {
		max-width: 604px;
	}

	.sidebar .archive-meta,
	.attachment .entry-header,
	.search.sidebar .page-content,
	.blog.sidebar .page-content,
	.sidebar .site-info,
	.sidebar .comments-title,
	.sidebar .no-comments {
		padding-left: 0;
		padding-right: 0;
	}

	.attachment .entry-meta {
		float: left;
		text-align: left;
		width: 100%;
	}

	.attachment .entry-content {
		max-width: 100%;
		padding: 40px 0;
	}

	.format-status .entry-content {
		padding-top: 40px;
	}

	.format-status .entry-meta {
		padding-bottom: 40px;
	}

	.sidebar .format-status .entry-content,
	.sidebar .format-status .entry-meta {
		padding-left: 35px;
	}

	.sidebar .format-status .entry-content:before,
	.sidebar .format-status .entry-meta:before {
		left: 10px;
	}

	.sidebar .format-status .entry-content p:first-child:before {
		left: 4px;
	}

	.sidebar .paging-navigation .nav-links {
		padding: 0 60px;
	}

	.site-main .sidebar-container {
		height: auto;
		margin: 0 auto;
		max-width: 604px;
		position: relative;
		top: 20px;
	}

	.site-main .widget-area {
		float: none;
		margin: 0;
		width: 100%;
	}

	.sidebar .site-footer .widget-area {
		max-width: 100%;
		left: 0;
	}
}

/* Collapse oversized image and pulled images after iPad breakpoint. */
@media (max-width: 767px) {
	.site-header .home-link {
		min-height: 0;
	}
	.site-title {
		font-size: 36px;
		padding: 8px 0 10px;
	}
	.entry-content img.alignleft,
	.entry-content .wp-caption.alignleft {
		margin-left: 0;
	}

	.entry-content img.alignright,
	.entry-content .wp-caption.alignright {
		margin-right: 0;
	}

	.attachment .image-navigation,
	.attachment .entry-attachment .attachment {
		max-width: 604px;
		padding: 0;
		width: 100%;
	}

	.gallery-caption {
		display: none;
	}
}

@media (max-width: 643px) {
	.site-title {
		font-size: 30px;
	}

	#content .entry-header,
	#content .entry-content,
	#content .entry-summary,
	#content footer.entry-meta,
	#content .featured-gallery,
	.search.sidebar .page-content,
	.blog.sidebar .page-content,
	.sidebar .post-navigation .nav-links,
	.paging-navigation .nav-links,
	#content .author-info,
	.comments-area .comments-title,
	.comments-area .comment-list,
	.comments-area .comment-navigation,
	.comment-respond,
	.sidebar .site-info,
	.sidebar .paging-navigation .nav-links {
		padding-left: 20px;
		padding-right: 20px;
	}

	#content .format-status .entry-content,
	#content .format-status .entry-met {
		padding-left: 35px;
	}

	/* Small menu */
	.menu-toggle {
		cursor: pointer;
		display: inline-block;
		font: bold 16px/1.3 "Source Sans Pro", Helvetica, sans-serif;
		margin: 0;
	}

	.menu-toggle,
	.menu-toggle:hover,
	.menu-toggle:focus,
	.menu-toggle:active {
		background: none;
		border: none;
		color: #141412;
		padding: 12px 0 12px 20px;
	}

	.menu-toggle:focus {
		outline: thin dotted;
	}

	.menu-toggle:after {
		content: "\F502";
		font-size: 12px;
		padding-left: 8px;
		vertical-align: -4px;
	}

	.toggled-on .menu-toggle:after {
		content: "\F500";
		vertical-align: 2px;
	}

	.toggled-on .nav-menu,
	.toggled-on .nav-menu > ul {
		display: block;
		margin-left: 0;
		padding: 0;
		width: 100%;
	}

	.toggled-on li,
	.toggled-on .children {
		display: block;
	}

	.toggled-on .nav-menu li > ul {
		background-color: transparent;
		display: block;
		float: none;
		margin-left: 20px;
		position: relative;
		left: auto;
		top: auto;
	}

	.toggled-on .nav-menu li > ul a {
		color: #141412;
		width: auto;
	}

	.toggled-on .nav-menu li:hover > a,
	.toggled-on .nav-menu .children a {
		background-color: transparent;
		color: #141412;
	}

	.toggled-on .nav-menu > li a:hover,
	.toggled-on .nav-menu > ul a:hover {
		background-color: #db572f;
		color: #fff;
	}

	.toggled-on .nav-menu > li a:focus,
	.toggled-on .nav-menu > ul a:focus {
		background-color: #220e10;
		color: #fff;
	}

	ul.nav-menu,
	div.nav-menu > ul {
		display: none;
	}

	#content .featured-gallery {
		padding-left: 24px;
	}

	.gallery-columns-1 .gallery-item {
		margin-right: 0;
		width: 100%;
	}

	.entry-title,
	.format-chat .entry-title,
	.format-image .entry-title,
	.format-gallery .entry-title,
	.format-video .entry-title {
		font-size: 22px;
		font-weight: bold;
	}

	.format-quote blockquote,
	.format-status .entry-content {
		font-size: 18px;
	}

	.format-quote blockquote small,
	.format-quote blockquote cite {
		font-size: 13px;
	}

	.error404 .page-title {
		padding: 40px 0 0;
	}

	.error404 .page-title:before {
		content: normal;
	}

	.comment-author {
		margin-right: 30px;
	}

	.comment-author .avatar {
		height: auto;
		max-width: 100%;
	}

	.comment-metadata,
	.comment-content,
	.comment-list .reply {
		width: 70%;
		width:         calc(100% - 104px);
	}

	.comment-form input[type="text"],
	.comment-form input[type="email"],
	.comment-form input[type="url"] {
		width:         calc(100% - 120px);
	}

	.comment-form textarea {
		height: 80px; /* Smaller field for mobile. */
	}

	/* Audio */
	.format-audio .entry-content:before {
		display: none;
	}

	.format-audio .audio-content {
		background-image: none;
		float: none;
		padding-left: 0;
		width: auto;
	}
}

/* Mobile devices */
@media (max-width: 359px) {
	.site-title {
		font-weight: normal;
	}
	.site-description {
	    clip: rect(1px, 1px, 1px, 1px);
	    position: absolute;
	}
	.gallery {
		margin-left: 0;
	}

	.gallery .gallery-item,
	.gallery-columns-2.gallery-size-thumbnail .gallery-item {
		max-width: none;
		width: 49%;
		width:         calc(50% - 4px);
	}

	.gallery-columns-1.gallery-size-medium,
	.gallery-columns-1.gallery-size-thumbnail,
	.gallery-columns-2.gallery-size-thumbnail,
	.gallery-columns-3.gallery-size-thumbnail {
		display: block;
	}

	.gallery-columns-1 .gallery-item,
	.gallery-columns-1.gallery-size-medium .gallery-item,
	.gallery-columns-1.gallery-size-thumbnail .gallery-item {
		text-align: center;
		width: 98%;
		width:         calc(100% - 4px);
	}

	.gallery-columns-3 .gallery-item:nth-of-type(3n),
	.gallery-columns-5 .gallery-item:nth-of-type(5n),
	.gallery-columns-7 .gallery-item:nth-of-type(7n),
	.gallery-columns-9 .gallery-item:nth-of-type(9n) {
		margin-right: 4px;
	}

	.gallery br {
		display: none;
	}

	.gallery .gallery-item:nth-of-type(even) {
		margin-right: 0;
	}

	/* Comments */
	.comment-author {
		margin: 0 0 5px;
		max-width: 100%;
	}

	.comment-author .avatar {
		display: inline;
		margin: 0 5px 0 0;
		max-width: 20px;
	}

	.comment-metadata,
	.comment-content,
	.comment-list .reply {
		width: 100%;
	}
}


/**
 * 9.0 Print
 * ----------------------------------------------------------------------------
 */

/* Retina-specific styles. */
@media print,
	(-webkit-min-device-pixel-ratio: 1.25),
	(min-resolution: 120dpi) {

	.site-header .search-field {
		/* background-image: url(images/search-icon-2x.png); */
	}

	.format-audio .audio-content,
	.format-status .entry-content:before,
	.format-status .entry-meta:before,
	.comment-list > li:after,
	.comment-list .children > li:before {
		/* background-image: url(images/dotted-line-2x.png); */
	}
}

@media print {
	body {
		background: none !important;
		color: #000;
		font-size: 10pt;
	}

	footer a[rel="bookmark"]:link:after,
	footer a[rel="bookmark"]:visited:after {
		content: " [" attr(href) "] "; /* Show URLs */
	}

	.site {
		max-width: 98%;
	}

	.site-header {
		background-image: none !important;
	}

	.site-header .home-link {
		max-width: none;
		min-height: 0;
	}

	.site-title {
		color: #000;
		font-size: 21pt;
	}

	.site-description {
		font-size: 10pt;
	}

	.author-avatar,
	.site-footer,
	.comment-respond,
	.comments-area .comment-edit-link,
	.comments-area .reply,
	.comments-link,
	.entry-meta .edit-link,
	.page-links,
	.site-content nav,
	.widget-area,
	.main-navigation,
	.navbar,
	.more-link {
		display: none;
	}

	.entry-header,
	.entry-content,
	.entry-summary,
	.entry-meta {
		margin: 0;
		width: 100%;
	}

	.page-title,
	.entry-title {
		font-size: 21pt;
	}

	.entry-meta,
	.entry-meta a {
		color: #444;
		font-size: 10pt;
	}

	.entry-content img.alignleft,
	.entry-content .wp-caption.alignleft {
		margin-left: 0;
	}

	.entry-content img.alignright,
	.entry-content .wp-caption.alignright {
		margin-right: 0;
	}

	.format-image .entry-content .size-full {
		margin: 0;
	}

	/* Remove colors from post formats */
	.hentry {
		background-color: #fff;
	}

	/* Comments */
	.comments-area > li.comment {
		background: none;
		position: relative;
		width: auto;
	}

	.comment-metadata {
		float: none;
	}

	.comment-author .fn,
	.comment-reply-link,
	.comment-reply-login {
		color: #333;
	}
}
/*
Theme Name: Twenty Thirteen
Description: Used to style blocks.
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Block Alignments
2.0 General Block Styles
3.0 Blocks - Common Blocks
4.0 Blocks - Formatting
5.0 Blocks - Layout Elements
6.0 Blocks - Widgets
7.0 Blocks - Colors
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Block Alignments
--------------------------------------------------------------*/

.content-area {
	overflow-x: hidden; /* prevents side-scrolling caused by use of vw */
}

[class^="wp-block-"].alignleft,
[class^="wp-block-"] .alignleft {
	margin-right: 20px;
}

[class^="wp-block-"].alignright,
[class^="wp-block-"] .alignright {
	margin-left: 20px;
}

.alignfull,
.alignwide {
	clear: both;
}

body:not(.sidebar) .alignwide {
	margin-left: calc(25% - 25vw);
	margin-right: calc(25% - 25vw);
	width: auto;
	max-width: 1600px;
}

body:not(.sidebar) .alignfull {
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
	width: auto;
	max-width: 1600px;
}

/* Make non image-based blocks a bit narrower, so they don't get cut off. */
body:not(.sidebar) .wp-block-columns.alignfull,
body:not(.sidebar) .wp-block-audio.alignfull,
body:not(.sidebar) .wp-block-table.alignfull,
body:not(.sidebar) .wp-block-latest-comments.alignfull {
	margin-left: calc(50% - 48vw);
	margin-right: calc(50% - 48vw);
}

@media (max-width: 999px) {
	body.sidebar .alignwide {
		margin-left: calc(25% - 25vw);
		margin-right: calc(25% - 25vw);
		width: auto;
		max-width: 1600px;
	}

	body.sidebar .alignfull {
		margin-left: calc(50% - 50vw);
		margin-right: calc(50% - 50vw);
		width: auto;
		max-width: 1600px;
	}

	/* Make non image-based blocks a bit narrower, so they don't get cut off. */
	body.sidebar .wp-block-columns.alignfull,
	body.sidebar .wp-block-audio.alignfull,
	body.sidebar .wp-block-table.alignfull,
	body.sidebar .wp-block-latest-comments.alignfull {
		margin-left: calc(50% - 48vw);
		margin-right: calc(50% - 48vw);
	}
}

@media (min-width: 1600px) {

	/* Make sure the full and wide blocks still stay in Twenty Thirteen's wide container */
	body:not(.sidebar) .alignfull {
		margin-left: calc(50% - 800px);
		margin-right: calc(50% - 800px);
		width: auto;
		max-width: 1000%;
	}

	body:not(.sidebar) .wp-block-columns.alignfull,
	body:not(.sidebar) .wp-block-audio.alignfull,
	body:not(.sidebar) .wp-block-table.alignfull,
	body:not(.sidebar) .wp-block-latest-comments.alignfull {
		margin-left: calc(50% - 780px);
		margin-right: calc(50% - 780px);
	}

	body:not(.sidebar) .wp-block-gallery.alignfull {
		margin-left: calc(50% - 808px); /* Adjust for gallery margins */
		margin-right: calc(50% - 808px);
		width: auto;
		max-width: 1000%;
	}

	/* Make sure the 'wide' alignment doesn't get too wide. */
	body:not(.sidebar) .alignwide {
		margin-left: -230px;
		margin-right: -230px;
	}
}

/*--------------------------------------------------------------
2.0 General Block Styles
--------------------------------------------------------------*/

/* Captions */

[class^="wp-block-"] figcaption,
[class^="wp-block-"] figcaption.editor-rich-text__tinymce.mce-content-body {
	color: #220e10;
	font-size: 18px;
	font-style: italic;
	font-weight: 300;
	line-height: 1.5;
	margin: 0 0 24px;
}

/*--------------------------------------------------------------
3.0 Blocks - Common Blocks
--------------------------------------------------------------*/

/* Paragraph */

p.has-drop-cap:not(:focus)::first-letter {
	font-size: 5em;
}

/* Gallery */

.wp-block-gallery {
	margin-bottom: 24px;
}

.wp-block-gallery figcaption {
	margin-bottom: 0;
}

/* Quote */

.wp-block-quote {
	margin: 24px 40px;
	padding: 0;
}

.wp-block-quote:not(.is-large):not(.is-style-large) {
	border-left: 0;
	border-right: 0;
	padding-left: 0;
	padding-right: 0;
}

.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
	font-size: 28px;
}

.wp-block-quote cite {
	color: inherit;
	font-size: inherit;
	font-style: italic;
}

/* Audio */

.wp-block-audio audio {
	display: block;
	width: 100%;
}

/* Cover */

.wp-block-cover-image.aligncenter,
.wp-block-cover.aligncenter {
	clear: both;
	display: flex;
}

/* File */

.wp-block-file .wp-block-file__button {
	background: #e05d22; /* Old browsers */ /* Chrome 10+, Safari 5.1+ */
	background:   linear-gradient(to bottom, #e05d22 0%, #d94412 100%); /* W3C */
	border: none;
	border-radius: 2px;
	border-bottom: 3px solid #b93207;
	color: #fff;
	display: inline-block;
	font-size: 16px;
	padding: 11px 24px 10px;
	text-decoration: none;
}

.wp-block-file .wp-block-file__button:hover,
.wp-block-file .wp-block-file__button:focus {
	background: #ed6a31; /* Old browsers */ /* Chrome 10+, Safari 5.1+ */
	background: linear-gradient(to bottom, #ed6a31 0%, #e55627 100%); /* W3C */
	color: #fff;
	outline: none;
}

.wp-block-file .wp-block-file__button:active {
	background: #d94412; /* Old browsers */ /* Chrome 10+, Safari 5.1+ */
	background:   linear-gradient(to bottom, #d94412 0%, #e05d22 100%); /* W3C */
	border: none;
	border-top: 3px solid #b93207;
	padding: 10px 24px 11px;
}

/*--------------------------------------------------------------
4.0 Blocks - Formatting
--------------------------------------------------------------*/

/* Code */

.wp-block-code {
	background-color: transparent;
	border: 0;
	padding: 0;
}

/* Pullquote */

.wp-block-pullquote {
	border: 0;
	color: inherit;
	padding: 0.5em 0;
}

.wp-block-pullquote__citation,
.wp-block-pullquote cite,
.wp-block-pullquote footer {
	color: inherit;
}

/* Table */

.wp-block-table {
	border-bottom: 1px solid #ededed;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 14px;
	line-height: 2;
	margin: 0 0 20px;
	width: 100%;
}

.wp-block-table th {
	border: 0;
	font-weight: bold;
	text-transform: uppercase;
}

.wp-block-table td {
	border: 0;
	border-top: 1px solid #ededed;
}

body:not(.sidebar) .wp-block-table.alignwide,
body:not(.sidebar) .wp-block-table.alignfull {
	width: 100%;
}

/* Some acrobatics to make sure the table blocks always fill the available space. */

@media (max-width: 1599px) {
	body:not(.sidebar) .wp-block-table.alignwide {
		width: calc(302px + 50vw); /* Half the content area width plus half the screen width. */
	}

	body:not(.sidebar) .wp-block-table.alignfull {
		width: 96vw;
	}
}

@media (max-width: 999px) {
	body.sidebar .wp-block-table.alignwide {
		width: calc(302px + 50vw); /* Half the content area width plus half the screen width. */
	}

	body.sidebar .wp-block-table.alignfull {
		width: 96vw;
	}
}

@media (max-width: 654px) {
	body:not(.sidebar) .wp-block-table.alignwide,
	body.sidebar .wp-block-table.alignwide {
		width: 96vw;
	}
}

@media (min-width: 1600px) {
	body:not(.sidebar) .wp-block-table.alignwide {
		width: 1064px;
	}

	body:not(.sidebar) .wp-block-table.alignfull {
		width: 1560px;
	}
}

/*--------------------------------------------------------------
5.0 Blocks - Layout Elements
--------------------------------------------------------------*/

/* Buttons */

.wp-block-button.alignleft {
	margin-right: 20px;
}

.wp-block-button.alignright {
	margin-left: 20px;
}

.wp-block-button .wp-block-button__link {
	border-bottom: 3px solid #b93207;
	display: inline-block;
	font-size: 16px;
	padding: 11px 24px 10px;
	text-decoration: none;
}

.entry-content .wp-block-button__link {
	background-color: #e05d22;
	color: #fff;
}

.entry-content .wp-block-button:not(.is-style-outline) .wp-block-button__link:not(.has-background) { /* Chrome 10+, Safari 5.1+ */
	background:   linear-gradient(to bottom, #e05d22 0%, #d94412 100%); /* W3C */
}

.entry-content .wp-block-button__link:hover,
.entry-content .wp-block-button__link:focus,
.wp-block-button.is-style-outline .wp-block-button__link:not(.has-text-color):hover,
.wp-block-button.is-style-outline .wp-block-button__link:not(.has-text-color):focus {
	background: #ed6a31;
	color: #fff;
}

.wp-block-button.is-style-outline .wp-block-button__link {
	background-color: inherit;
	border-color: inherit;
	border-width: 2px;
}

.wp-block-button.is-style-outline .wp-block-button__link:not(.has-text-color) {
	color: #ed6a31;
}

.entry-content .wp-block-button__link:not(.has-background):hover,
.entry-content .wp-block-button__link:not(.has-background):hover,
.entry-content .wp-block-button:not(.is-style-outline) .wp-block-button__link:not(.has-background):hover { /* Chrome 10+, Safari 5.1+ */
	background: linear-gradient(to bottom, #ed6a31 0%, #e55627 100%); /* W3C */
}

.entry-content .wp-block-button__link:active {
	background: #d94412;
}

.entry-content .wp-block-button__link:not(.has-background):active { /* Chrome 10+, Safari 5.1+ */
	background:   linear-gradient(to bottom, #d94412 0%, #e05d22 100%); /* W3C */
}

.entry-content .wp-block-button .wp-block-button__link:active {
	border: none;
	border-top: 3px solid #b93207;
	padding: 10px 24px 11px;
}

/* Separator */

.wp-block-separator {
	border: 0;
	max-width: 100px;
}

.wp-block-separator.is-style-wide {
	max-width: 100%;
}

/* Group Block: Default Alignment */

.wp-block-group,
.wp-block-group.has-background {
	margin-bottom: 24px;
}

.wp-block-group:not(.alignfull):not(.alignwide) > .wp-block-group__inner-container > * {
	width: 604px;
	max-width: 100%;
	margin-left: 0;
	margin-right: 0;
}

.wp-block-group.has-background:not(.alignfull):not(.alignwide) > .wp-block-group__inner-container > .alignfull {
	width: calc(100% + 60px);
	max-width: calc(100% + 60px);
	margin-left: -30px;
}

.wp-block-group:not(.alignfull) .wp-block-columns.alignfull {
	margin-left: auto;
	margin-right: auto;
}

/* Group Block: Wide Alignment */

.wp-block-group.alignwide > .wp-block-group__inner-container > *,
.wp-block-group.alignwide > .wp-block-group__inner-container > .alignwide,
.wp-block-group.alignwide > .wp-block-group__inner-container > .alignfull {
	max-width: 604px;
	margin-left: auto;
	margin-right: auto;
}

.wp-block-group.alignwide > .wp-block-group__inner-container > .alignwide,
.wp-block-group.alignwide > .wp-block-group__inner-container > .alignfull {
	width: 100%;
	max-width: 100%;
}

.wp-block-group.has-background.alignwide > .wp-block-group__inner-container > .alignfull {
	width: calc(100% + 60px);
	max-width: calc(100% + 60px);
	margin-left: -30px;
}

@media (max-width: 664px) {
	.wp-block-group:not(.has-background).alignwide {
		margin-left: 0;
		margin-right: 0;
	}

	.wp-block-group:not(.has-background).alignwide > .wp-block-group__inner-container > .alignwide,
	.wp-block-group:not(.has-background).alignwide > .wp-block-group__inner-container > .alignfull {
		width: auto;
		max-width: 1600px;
		margin-left: calc(25% - 25vw);
		margin-right: calc(25% - 25vw);
	}
}

/* Group Block: Full Alignment */

@media (max-width: 664px) {
	.wp-block-group:not(.has-background).alignfull {
		margin-left: 0;
		margin-right: 0;
	}

	.wp-block-group.has-background.alignfull > *:not(.alignfull) {
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 999px) {
	body.sidebar .wp-block-group.alignfull:not(.has-background) {
		margin-left: 0;
		margin-right: 0;
	}

	body.sidebar .wp-block-group.alignfull.has-background > .wp-block-group__inner-container {
		max-width: 604px;
		margin: 0 auto;
	}

	body.sidebar .wp-block-group.alignfull:not(.has-background) > .wp-block-group__inner-container > .alignfull {
		margin-left: calc(50% - 50vw);
		margin-right: calc(50% - 50vw);
	}
}

@media (min-width: 665px) {
	body:not(.sidebar) .wp-block-group.alignfull,
	body:not(.sidebar) .wp-block-group.has-background.alignfull {
		padding: 20px calc( (100vw - 604px ) / 2 ); /* Reproduces the padding of entry-content, so that default block rules apply properly inside of this block. */
	}
}

@media (min-width: 1600px) {
	body:not(.sidebar) .wp-block-group.alignfull,
	body:not(.sidebar) .wp-block-group.has-background.alignfull {
		padding: 20px 498px; /* Above 1600px, these need fixed left/right padding. This works out to ((1600px - 604px) / 2) */
	}
}

/* Media & Text */

.wp-block-media-text {
	margin-bottom: 24px;
}

.wp-block-media-text *:last-child {
	margin-bottom: 0;
}

/*--------------------------------------------------------------
6.0 Blocks - Widgets
--------------------------------------------------------------*/

/* Archives, Categories & Latest Posts */

.wp-block-archives.aligncenter,
.wp-block-categories.aligncenter,
.wp-block-latest-posts.aligncenter {
	list-style-position: inside;
	text-align: center;
}

/* Latest Comments */

.wp-block-latest-comments {
	margin: 0;
	padding: 0;
}

.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
	border-radius: 0;
}

.wp-block-latest-comments__comment,
.wp-block-latest-comments__comment-excerpt,
.wp-block-latest-comments__comment-excerpt p {
	font-size: 16px;
}

.wp-block-latest-comments__comment-excerpt p:last-child {
	margin-bottom: 0;
}

.wp-block-latest-comments__comment-date {
	font-size: 16px;
}

.wp-block-latest-comments .wp-block-latest-comments__comment {
	/*background: url(../images/dotted-line.png) repeat-x left top; */
	margin-bottom: 0;
	padding: 24px 0;
}

/*--------------------------------------------------------------
7.0 Blocks - Colors
--------------------------------------------------------------*/

.entry-content .has-dark-gray-color {
	color: #141412;
}

.entry-content .has-dark-gray-background-color {
	background-color: #141412;
}

.entry-content .has-red-color {
	color: #bc360a;
}

.entry-content .has-red-background-color {
	background-color: #bc360a;
}

.entry-content .has-medium-orange-color {
	color: #db572f;
}

.entry-content .has-medium-orange-background-color {
	background-color: #db572f;
}

.entry-content .has-light-orange-color {
	color: #ea9629;
}

.entry-content .has-light-orange-background-color {
	background-color: #ea9629;
}

.entry-content .has-yellow-color {
	color: #fbca3c;
}

.entry-content .has-yellow-background-color {
	background-color: #fbca3c;
}

.entry-content .has-white-color {
	color: #fff;
}

.entry-content .has-white-background-color {
	background-color: #fff;
}

.entry-content .has-dark-brown-color {
	color: #220e10;
}

.entry-content .has-dark-brown-background-color {
	background-color: #220e10;
}

.entry-content .has-medium-brown-color {
	color: #722d19;
}

.entry-content .has-medium-brown-background-color {
	background-color: #722d19;
}

.entry-content .has-light-brown-color {
	color: #eadaa6;
}

.entry-content .has-light-brown-background-color {
	background-color: #eadaa6;
}

.entry-content .has-beige-color {
	color: #e8e5ce;
}

.entry-content .has-beige-background-color {
	background-color: #e8e5ce;
}

.entry-content .has-off-white-color {
	color: #f7f5e7;
}

.entry-content .has-off-white-background-color {
	background-color: #f7f5e7;
}
